// Load CSS Reset from NPM
@import "~normalize.css";
$vermelho: #780000;
$dourado: #d69947;

#navbar {
  height: 90px;
  background: $vermelho;
  position: relative;

  & .container {
    position: relative;
  }

  img {
    height: 50px;
  }
}

h1 {
  margin: 1.5rem 0 1.5rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  // color: #fff;
}

.main-container {
  flex-direction: column;
  background-color: $vermelho;
  height: calc(100vh - 90px);
  overflow: hidden !important;
}

.chat-wrapper {
  margin: 1rem 0;
  height: calc(100% - 75px);
}

.chat-container {
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 25px;
  position: relative;
  display: flex;
  flex-direction: column;

  & .chat {
    overflow: auto;
    padding: 0 2rem;
    margin: 1.5rem 1rem 1rem 0;
    flex: 1;
  }
}

.mensagem {
  border-radius: 25px;
  background-color: #f0f0f0;
  // transition: padding 0.3s;
  // background: linear-gradient(90deg, #f39b4c, #d35252);
  padding: 1.5rem 2rem;
  font-size: 18px;
  line-height: calc(18px * 1.618);
  max-width: 70%;
  min-width: 100px;
  width: fit-content;
  font-family: "Montserrat", sans-serif;
  animation: escala 0.6s forwards;

  // color: #fff;
  &:first-child {
    margin-top: 0.5rem;
  }

  &:not(:first-child) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.bot {
    border-top-left-radius: 0;
    margin-right: auto;
    text-align: left;
  }

  &.user {
    border-top-right-radius: 0;
    margin-left: auto;
    text-align: right;
    color: #fff;
    background: $dourado;
  }

  &.loading {
    border-top-left-radius: 0;
    margin-right: auto;
    min-width: 100px;
    // font-size: 26px;
    padding: 1rem 2rem;

    &::after {
      content: " .";
      animation: dots 1s steps(5, end) infinite;
    }
  }

  &.erro {
    background-color: #f34747;
    color: #fff;
    font-weight: bold;
  }

  &.planos {
    display: inline-flex;
    margin-right: 20px;
    cursor: pointer;

    & img {
      height: 100px;
      margin-bottom: 15px;

      &.ouro {
        height: 150px;
      }
    }

    & p {
      margin-bottom: 0;
      text-align: center;
      font-weight: bold;
    }
  }
}

@keyframes escala {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: #010101;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow: 0.25em 0 0 #010101, 0.5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow: 0.25em 0 0 #010101, 0.5em 0 0 #010101;
  }
}

.input-container {
  width: 100%;
  padding: 0 3.2rem 0 0;
  border-top: 2px solid #f2f2f2;
  border-radius: 0 0 25px 25px;
  display: flex;

  &.disabled {
    background-color: #f2f2f2;
    cursor: not-allowed;
  }

  & button {
    &:disabled {
      pointer-events: none;
      cursor: not-allowed;
      background-color: transparent;
    }
  }

  & input {
    border: none;
    width: 80%;
    color: #101010;
    padding: 2rem 0 2rem 3.2rem;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 25px;
    border-radius: 0 0 0 25px;
    flex: 1;

    &:disabled {
      cursor: no-drop;
      color: #ccc !important;
      background-color: transparent;
    }

    &::-webkit-input-placeholder {
      color: #ccc;
    }

    &:-ms-input-placeholder {
      color: #ccc;
    }

    &::placeholder {
      color: #ccc;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:-internal-autofill-selected {
      background-color: #fff !important;

      &:disabled {
        color: #ccc !important;
      }
    }
  }
}

.termos {
  background-color: #f0f0f0;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 0 0 25px 25px;
}

.concluido {
  background: #57d991;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  border-radius: 0 0 25px 25px;
}

.footer {
  position: fixed;
  bottom: 0;
  // color: #fff;
  width: 100%;
  padding: 0 0 10px 15px;

  & a,
  & a:hover {
    // color: #fff;
    text-decoration: none;
  }
}

@media screen and (max-width: 615px) {
  #navbar {
    height: 60px;
    padding-top: 1rem;

    img {
      height: 30px;
    }
  }

  .input-container {
    padding: 0 1rem 0 0;

    & input {
      font-family: "Montserrat", sans-serif;
      font-weight: bold;
      font-size: 18px;
      padding: 1.5rem 0 1.5rem 1.5rem;
    }

    & img {
      height: 20px;
    }
  }

  input::-webkit-input-placeholder {
    line-height: normal !important;
  }

  h1 {
    font-size: 1rem;
    margin: 8px 0 0 0;
    line-height: normal !important;
    vertical-align: middle;
    margin-top: 0;
  }

  .chat-wrapper {
    padding: 1rem 0.1rem;
    margin: 0 0 0;
    height: 95%;
  }

  .main-container {
    height: calc(100% - 60px);
  }

  .chat-container {
    border-radius: 16px;

    & .chat {
      padding: 0 1rem;
      margin: 1.5rem 0.5rem 1rem 0;
    }
  }

  footer {
    text-align: center;
    padding-left: 0;
  }

  .mensagem {
    max-width: 100%;
    border-radius: 22px;
    font-size: 15px;
    padding: 1rem 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .concluido {
    border-radius: 0 0 16px 16px;
  }

  .termos > button {
    width: 100%;
  }
}

.button-link {
  color: #007bff;
  background-color: transparent;
  border: none;
}

/* scrollbar */
#chat::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

#chat::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

#chat::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

#chat::-webkit-scrollbar-thumb:hover {
  background: #555;
}
