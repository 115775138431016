/* @font-face {
    font-family: "SummerLoving-Regular";
    src: url("../fonts/SummerLovingRegular.eot");
    src: url("../fonts/SummerLovingRegular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/SummerLovingRegular.otf") format("opentype"),
        url("../fonts/SummerLovingRegular.svg") format("svg"),
        url("../fonts/SummerLovingRegular.ttf") format("truetype"),
        url("../fonts/SummerLovingRegular.woff") format("woff"),
        url("../fonts/SummerLovingRegular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
} */

@import "~normalize.css";

body {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

#associese {
  margin-top: -3rem;
}

h5.title {
  font-weight: bold;
  font-size: 1.1rem;
  color: #fff;
}

p {
  margin-bottom: 0;
}

#logo_futsal {
  height: 150px;
}

#details {
  /* width: 50%; */
  margin: 4rem auto 2rem auto;
  font-size: 1rem;
}

#details > p {
  font-size: 1.1rem;
  margin-top: 1.1rem;
  color: #fff;
}

p.contatos {
  color: #fff;
  margin: 0;
}

/* 
.btn-cadastrar {
    background-color: #D69947;
    color: #fff;
    font-weight: bold;
    width: 50%;
    font-size: 16px;
    padding: 15px 20px;
    margin: 5rem auto 8rem auto;
} */

#planos {
  color: #000000;
  margin-bottom: 2rem;
}

#planos h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  line-height: calc(1.75rem * 1.5);
}

#planos p {
  text-align: left;
  border-left: 4px solid;
  border-color: #e1e1e1;
  padding-left: 15px;
  line-height: calc(0.9rem * 1.4);
  margin-top: 8px;
  font-size: 0.9rem;
  margin-bottom: 0;
}

#planos .card-planos {
  padding: 28px 22px;
  text-align: center;
  border-radius: 20px;
  flex-direction: column;
  transition: 0.4s box-shadow;
}

#planos .card-planos:hover {
  box-shadow: 1px 8px 20px rgba(0, 0, 0, 0.3);
  transition: 0.4s box-shadow;
}

#planos .card-planos img {
  height: 150px;
}

#planos .card-planos h4 {
  margin-top: 1rem;
  margin-bottom: 0;
  margin-top: 2rem;
}

#planos .card-planos small {
  color: #767676;
}

#planos .card-planos .preco {
  width: 100%;
}

.btn-cadastrar {
  background-color: #d69947 !important;
  color: #fff !important;
  font-weight: bold !important;
  width: 80% !important;
  font-size: 16px !important;
  padding: 15px 20px !important;
  margin: 1.5rem 0 0 !important;
}

.btn-cadastrar:hover {
  color: #000 !important;
}

.btn-cadastrar:focus {
  box-shadow: 0;
}

#contatos {
  width: 75%;
  margin: 2rem auto auto;
}

#contatos > div p {
  font-size: 10px;
}

#contatos > div p:first-child {
  font-weight: bold;
  font-size: 12px;
}

.social-media img {
  height: 32px;
  margin-top: 15px;
  margin-right: 15px;
}

#contatos .col-sm-3 img {
  width: 100%;
  margin: auto;
}

footer {
  width: 100%;
  padding: 2rem 0;
  position: relative;
}

footer .row {
  width: 75%;
  margin: auto;
}

// @media screen and (min-width: 768px) {
//   #planos .row>div:first-child .card-planos {
//     transform: scale(1.02);
//   }

//   #planos .row>div:not(:first-child) .card-planos {
//     transform: scale(0.98);
//   }
// }

@media screen and (max-width: 1200px) {
  #logo_futsal {
    height: 100px;
  }
}

@media screen and (max-width: 992px) {
  #contatos,
  footer .row {
    width: 80%;
  }

  #details {
    width: 70%;
  }

  #planos .card-planos {
    padding: 22px 16px;
    text-align: center;
    border-radius: 20px;
    margin-bottom: 2rem;
  }

  #planos .card-planos h3 {
    font-size: 1.5rem;
    line-height: calc(1.5rem * 1.5);
  }

  #planos .card-planos p {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
  #contatos,
  footer .row {
    width: 100%;
  }

  #details {
    width: 80%;
  }

  #planos .card-planos {
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    padding: 32px 18px;
  }
}

@media screen and (max-width: 575px) {
  #logo_futsal {
    height: 100px;
  }

  #associese {
    margin-top: -2rem;
  }

  #associese > img {
    width: 80%;
  }

  #planos {
    margin-bottom: 0;
  }

  #planos .card-planos {
    width: 100%;
    /* margin-bottom: 2rem; */
  }

  #details {
    width: 96%;
    margin: 3rem auto auto auto;
  }

  .btn-cadastrar {
    width: auto;
    margin-top: 1.5rem;
    margin-bottom: 0.3rem;
    padding: 15px 50px;
  }

  #contatos .col-sm-3 img {
    height: 45px;
    width: auto;
    margin: auto;
  }
}
