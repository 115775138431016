// @import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css");
@import '~normalize.css';
$vermelho: #780000;
$dourado: #d69947;
$verde: #00a783;
$amarelo: #f9aa3b;
$vermelhoVivo: #de002c;
$quaseBranco: #f0f2f5;
$socioTextoEscuro: #58323b;
$cinza: #969cb2;
$preto: #333333;
$branco: #fff;
$cinzaClaroAzulado: #acb7c1;

* {
  -webkit-font-smoothing: antialiased;
}

body,
pre {
  font-family: 'Montserrat', sans-serif !important;
}

pre {
  margin: 0;
}

body {
  overflow-x: hidden;
  letter-spacing: calc((10em / 1000) * -1);
}

button:focus {
  outline: 0;
}

a:hover {
  color: currentColor;
}

html,
body,
#root {
  min-height: 100vh;
}

html,
body,
#root {
  min-height: 100vh;
}

.main-nav {
  // background-color: $vermelho;
  background-color: $branco;
}

.login-container {
  height: 100vh;
  width: 100vw;
  background-color: $vermelho;
}

.loader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 9999;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.font-login {
  font-size: 16px;
  color: #333;
}

.logo-login {
  & > img {
    max-width: 55%;
  }
}

.user-icon {
  height: 32px;
}

.apexcharts-menu-item.exportPNG {
  display: none;
}

.sidebar {
  height: 100%;
  width: 515px;
  position: fixed;
  z-index: 99;
  top: 0;
  right: -100%;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s ease-in-out;
  padding-top: 80px;
  padding-bottom: 3rem;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);

  &.aberto {
    right: 0;
    transition: 0.5s ease-in-out;
  }

  & a,
  & .with-subs {
    padding: 5% 3% 0% 20%;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif !important;
    color: #000000;
    display: block;
    transition: 0.3s;

    &:hover {
      color: black;
    }
  }

  & .closebtn {
    position: absolute;
    padding-top: 8%;
    top: 0;
    right: 20%;
    font-size: 150%;
    color: #000000;
  }

  @media screen and (max-width: 450px) {
    width: 100%;

    & a {
      font-size: 18px;
    }
  }
}

.tituloCadastroSocio {
  font-size: 124%;
}

.corDependente {
  background-color: #f4f4f4;
}

// /* scrollbar */
// ::-webkit-scrollbar {
//     width: 8px;
//     border-radius: 10px;
// }

// ::-webkit-scrollbar-track {
//     background: #f1f1f1;
//     border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb {
//     background: #888;
//     border-radius: 10px;
// }

// ::-webkit-scrollbar-thumb:hover {
//     background: #555;
//     border-radius: 10px;
// }

button:disabled {
  cursor: not-allowed;
}

// .form-group.required
.control-label:after {
  content: '*';
  color: red;
}

.nuvoni {
  color: #fff;
}

.nuvoni:hover {
  color: #fff;
  text-decoration: underline !important;
}

.card-label {
  height: auto;
  font-size: 3rem;
  font-weight: bold;
}

.bt-0 {
  border-top: 0 !important;
}

.titleCard {
  font-size: 1rem;
}

@media (max-width: 1200px) {
  .titleCard {
    font-size: 86%;
  }
}

.font-weight-600 {
  font-weight: 600 !important;
}

.bg-verm {
  background-color: $vermelho;
}

.bg-selected {
  background-color: #f4f4f4;
}

.label {
  font-size: 0.7rem;
}

.AA {
  margin-bottom: -20px;
}

.cursor-pointer {
  cursor: pointer;
}

.retorno-invalido {
  color: #dc3545;
  font-size: 80%;
  width: 100%;
  margin-top: 0.25rem;
  font-weight: 400;
}

.white-button {
  display: inline-block;
  color: $dourado;
  background-color: #f6f6f6;
  border: 0;
  font-weight: bold;
  padding: 12px 24px;
  transition: all 0.3s ease-in;

  &:hover {
    text-decoration: none;
    color: lighten($color: $dourado, $amount: 10);
    box-shadow: 0 8px 20px rgba($color: #f6f6f6, $alpha: 0.2);
  }
}

.info-live {
  @media (max-width: 530px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.img-live {
  width: 100%;

  @media (max-width: 530px) {
    height: 250px;
    margin-bottom: 30px;
  }
}

.title-live {
  @media (max-width: 530px) {
    font-size: 1.5rem;
  }
}

.text-live {
  @media (max-width: 530px) {
    font-size: 1rem;
  }
}

.live-wrap {
  flex-direction: row;

  @media (max-width: 530px) {
    flex-direction: column-reverse;
  }
}

.header-title {
  font-size: 1.5rem;
  margin: 0;
  color: $branco;
}

@media screen and (max-width: 615px) {
  .header-title {
    font-size: 1rem;
    margin: 0;
  }
}

.mw-50 {
  max-width: 50% !important;
}

.color-primary {
  color: $vermelho;
}

.socials {
  @media (max-width: 767px) {
    margin-top: 1rem;
  }
}

.form-control:focus,
.custom-select {
  option {
    &:disabled {
      background: #ccc;
      color: #FFF
    }
  }
}
.custom-select:focus {
  border-color: #6c757d;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
  -webkit-box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.25);
}

.rc-steps-item-process .rc-steps-item-icon {
  border-color: $dourado !important;
}

.rc-steps-item-process .rc-steps-item-icon {
  background: $dourado !important;
}

.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: $dourado !important;
}

.rc-steps-item-finish .rc-steps-item-icon {
  border-color: $dourado !important;
  background-color: $dourado !important;
}

.no-decoration,
.no-decoration:hover {
  text-decoration: none;
  color: #212529;
}

.nav-pills > a.active {
  background-color: #343a40 !important;
}

.nav-pills > a {
  color: #343a40;
}

.nav-pills > a:hover {
  color: #606b76;
}

.fs-14 {
  font-size: 14px;
}

.fs-141 {
  font-size: 14px;
  inline-size: 170px;
  overflow-wrap: break-word;
}

.fs-18 {
  font-size: 18px;
}

.fs-24 {
  font-size: 24px;
}

.pt-8 {
  padding-top: 80px;
}

.mb-6 {
  margin-bottom: 40px;
}

.fw-light {
  font-weight: 300;
}

.fw-bold {
  font-weight: 700;
}

.min-h-100 {
  min-height: 100vh;
}

.whitespace-prewrap {
  white-space: pre-wrap;
}

.warning {
  font-size: 14px;
  font-weight: bold;
  font-style: italic;
  color: #999;
}

.row-gap-30 {
  grid-row-gap: 30px;
  row-gap: 30px;
}

.font-family-hind {
  font-family: 'Hind';
}

.action {
  position: absolute;
  bottom: 18px;
  right: 32px;
}

.mb-10 {
  margin-bottom: 10px;
}

.card-name {
  margin-right: 60px;
  // line-height: 14px;
  // max-width: 46ch;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #333;
  font-size: 16px;
}

.ml-18 {
  margin-left: 18px;
}

.z-index-99 {
  z-index: 99 !important;
}

.z-index-999 {
  z-index: 999 !important;
}

.color-black {
  transition: color 0.3s ease-out;
  color: #333 !important;
}

.color-white {
  transition: color 0.3s ease-out;
  color: #fff !important;
}

.custom-btn {
  font-size: 18px;

  & > svg {
    transition: color 0.4s ease-out;
  }
}

.custom-btn.focus,
.custom-btn:focus {
  box-shadow: 0 0 0 0.2rem rgba($color: $dourado, $alpha: 0.4);

  & > svg {
    color: rgba($color: $dourado, $alpha: 0.7);
  }
}

.custom-dropdown {
  transition: background-color 0.2s ease-out;

  & > svg {
    transition: color 0.1s ease-out;
  }

  & > span {
    transition: color 0.1s ease-out;
  }
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-lg,
.navbar-expand > .container-md,
.navbar-expand > .container-sm,
.navbar-expand > .container-xl {
  padding-right: 15px;
  padding-left: 15px;

  .ADC {
    display: flex;
    justify-content: right !important;
  }

  .ADD {
    display: flex;
    justify-content: right !important;
  }
}

.custom-dropdown.hover,
.custom-dropdown:hover {
  background-color: rgba($color: $dourado, $alpha: 0.15);
}

.custom-dropdown.active,
.custom-dropdown:active {
  background-color: $dourado;

  & > svg {
    color: $branco;
  }

  & > span {
    color: $branco;
  }
}

.apexcharts-title-text {
  color: #333;
}

.nav-item-bg {
  transition: background-color 0.2s, color 0.2s;
}

.bg-yellow {
  background-color: $dourado;
  transition: background-color 0.2s;
}

.bg-yellow-hover:hover {
  background-color: darken($color: $dourado, $amount: 5);
}

.carteirinha {
  font-size: 12px;
  font-weight: 600;
  // font-style: italic;
  margin-bottom: 0.5rem;
}

.status-ativo,
.status-inativo,
.status-cancelado {
  opacity: 0.3;
  transition: opacity 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.circle-status {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.status-color-ativo {
  background-color: $verde;
}

.status-color-inativo {
  background-color: $amarelo;
}

.status-color-cancelado {
  background-color: $vermelhoVivo;
}

.status-text {
  color: #333;
  font-weight: 600;
  font-size: 12px;
  margin-left: 6px;
  line-height: 16px;
  transform: translateY(1px);
}

.status-active-ativo {
  & > .status-ativo {
    opacity: 1;
  }
}

.status-active-inativo {
  & > .status-inativo {
    opacity: 1;
  }
}

.status-active-cancelado {
  & > .status-cancelado {
    opacity: 1;
  }
}

.margin-bottom-2 {
  margin-bottom: 2px;
}

.medal {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  transform: translate(-2px, 8px);
  color: white;
  // background-color:#57040c;
  background-color: rgb(214, 153, 71);
  padding: 5px;
  border-radius: 3px;
}

.outline-0 {
  outline: 0 !important;
}

.form-pesquisa {
  display: flex;
  flex: 1;
}

.custom-input {
  display: flex;
  flex: 1;
  align-items: center;
  background-color: #dbdbdb;
  border-radius: 8px;
  min-height: 40px;

  &.read-only {
    background-color: #f0f2f5;
    opacity: 0.8;

    & > label {
      cursor: not-allowed;
    }
  }

  & > input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 0;
    outline: 0;
    font-weight: 600;
    color: #717171;
    font-size: 16px;
    padding: 0;

    &:read-only {
      cursor: not-allowed;
    }

    &::placeholder {
      color: #a0a0a0;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: #717171;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  & > label {
    margin: 0;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 8px;
    height: 100%;
  }

  & > .send-button {
    height: 100%;
    padding-right: 20px;
    border: 0;
    background-color: transparent;
    outline: 0;
  }
}

@supports (display: grid) {
  .filter {
    width: 33.333333%;
    padding-left: 30px;
    list-style: none;
    display: grid;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    // align-items: flex-end;
    grid-template-columns: repeat(4, auto);

    & > .filter-item {
      background-color: transparent;
      border: 0;
      outline: 0;
      margin-bottom: 1px;
      font-weight: 500;
      display: flex;

      & > div {
        display: inline-block;
      }

      & > div > .filter-underline {
        width: 100%;
        height: 8px;
        padding: 0;
      }

      & > div > .filter-text {
        padding: 5px 0;
      }
    }

    @media (max-width: 1199px) {
      width: 52%;
    }

    @media (max-width: 767px) {
      width: 100%;
      margin-top: 24px;
      padding: 0 6px;
    }

    @media (max-width: 399px) {
      align-content: space-around;
      justify-content: space-between;
      grid-template-columns: repeat(2, auto);
    }
  }
}

.card-orientation {
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.card-orientationn {
  display: flex;
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.btn-adicionar {
  background-color: $verde;
  border: 0;
  width: 50px;
  height: 50px;
  font-size: 22px;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transform: translate(25%, -50%);
  box-shadow: 0px 8px 16px rgba(51, 51, 51, 0.25);
  transition: background-color 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: lighten($color: $verde, $amount: 2.5);
  }

  &:focus {
    outline: 0;
  }

  // @media (max-width: 575px) {
  //   transform: translate(25%, -50%);
  // }
}

.btn-report {
  background-color: $verde;
  border: 0;
  width: 50px;
  height: 50px;
  font-size: 22px;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  transform: translate(-85%, -50%);
  box-shadow: 0px 8px 16px rgba(51, 51, 51, 0.25);
  transition: background-color 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: lighten($color: $verde, $amount: 2.5);
  }

  &:focus {
    outline: 0;
  }

  // @media (max-width: 575px) {
  //   transform: translate(25%, -50%);
  // }
}

// .px-sm-0 {
//   @media (max-width: 575px) {
//     padding-right: 0;
//     padding-left: 0;
//   }
// }

.wrapper {
  width: 100%;
  height: calc(100vh - 105px);
  overflow-y: auto;
}

.logout-button {
  background-color: transparent;
  border: 0;

  &:focus {
    outline: 0;
  }
}

.color-gray-text {
  color: #a0a0a0;
}

.card-email {
  font-weight: 600;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 80%;

  @media (max-width: 1199px) {
    white-space: normal;
    text-overflow: unset;
    overflow: unset;
  }
}

.list-users {
  .item-user:last-child {
    & > .card-user {
      border-radius: 0 0 4px 4px;
    }
  }

  .item-user:nth-of-type(2n + 1) {
    & > .card-user {
      background-color: $branco;
    }
  }

  .item-user:nth-of-type(2n) {
    & > .card-user {
      background-color: #f3f4f7;
    }
  }

  @media (max-width: 1199px) {
    .item-user:first-child {
      & > .card-user {
        border-radius: 4px 4px 0 0;
      }
    }
  }
}

.empty-result-text {
  font-size: 20px;
  color: #fff;
}

.custom-table {
  width: 100%;

  & > thead {
    background-color: $branco;

    & > th {
      border: none;
      color: #666;
      font-size: 16px;
      font-weight: bold;
    }

    & > th:first-child {
      border-radius: 4px 0 0 0;
    }

    & > th:last-child {
      border-radius: 0 4px 0 0;
    }
  }

  & > tbody {
    & > tr {
      border: none;

      & > td {
        border: none;
        color: #666;
        font-size: 16px;        
      }
    }

    & > tr:last-child {
      & > td:first-child {
        border-radius: 0 0 0 4px;
      }

      & > td:last-child {
        border-radius: 0 0 4px 0;
      }
    }
  
    & > tr:nth-of-type(2n + 1) {
      background-color: $branco;
    }
  
    & > tr:nth-of-type(2n) {
      background-color: #f3f4f7;
    }
  }
}

.custom-table-card-header {
  background-color: $branco;
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 8px;

  & > span {
    color: #666;
    font-size: 16px;
    font-weight: bold;
  }
}

.custom-table-card {
  & > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 8px;
  }

  & > div > span {
    color: #666;
    font-size: 16px;
  }

  & > div:first-child {
    border-radius: 4px 4px 0 0;
  }

  & > div:last-child {
    border-radius: 0 0 4px 4px;
  }

  & > div:nth-of-type(2n + 1) {
    background-color: $branco;
  }

  & > div:nth-of-type(2n) {
    background-color: #f3f4f7;
  }
}

.dispo-id {
  color: #666;
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 0;
  width: 100%;
  max-width: 310px;
}

.name-user {
  color: #666;
  font-size: 14px;
  margin-bottom: 0;
  margin-right: 0;
  width: 100%;
  max-width: 310px;
}

.dispo-mac {
  width: 40%;
  color: #666;
  font-size: 14px;
  margin-left: 10%;
  margin-right: 6%;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.email-user {
  width: 50%;
  color: #666;
  font-size: 14px;
  margin-left: 10%;

  @media (max-width: 1199px) {
    margin-left: 0;
    font-size: 12px;
    margin-top: 8px;
  }
}
.ativo-dispositivos {
  width: 50%;
  margin-left: -2%;

  @media (max-width: 1199px) {
    margin-left: 6%;
  }
  @media (max-width: 990px) {
    margin-left: 0;
  }
}

.header-users {
  background-color: $branco;
  border-radius: 4px 4px 0 0;
  font-weight: bold;
  color: #666;

  & > p:nth-child(1) {
    width: 100%;
    max-width: 310px;
  }

  & > p:nth-child(2) {
    width: 100%;
    max-width: 310px;
    margin-left: 10%;

    @media (max-width: 1199px) {
      margin-left: 0;
    }
  }
}

.btn-verde {
  background-color: $verde;
  color: $branco;

  &:hover {
    background-color: lighten($color: $verde, $amount: 2.5);
    color: $branco;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($color: $verde, $alpha: 0.25);
  }
}

.nav-to-hide {
  @media (max-width: 1199px) {
    display: none;
  }
}

.hamburger-appear {
  // width: 30px;

  @media (min-width: 1200px) {
    display: none;
  }
}

.px-450-0 {
  @media (max-width: 1199px) {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.pr-450-0 {
  @media (max-width: 1199px) {
    padding-right: 0 !important;
  }
}

.hamburger-inner {
  background-color: $branco;
}

.hamburger-inner:before {
  background-color: $branco;
}

.hamburger-inner:after {
  background-color: $branco;
}

.menu-side {
  display: flex;
  width: 200vw;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 0;
  background-color: $branco;
  flex-direction: column;

  transition: left cubic-bezier(0.75, -0.5, 0, 1.7) 0.4s;

  justify-content: center;
  align-items: center;

  .menu-side-wrapper {
    display: flex;
    flex-direction: column;

    .menu-side-content {
      overflow-y: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .menu-side-content::-webkit-scrollbar {
      width: 8px;
      border-radius: 10px;
    }
    
    .menu-side-content::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
    
    .menu-side-content::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
    
    .menu-side-content::-webkit-scrollbar-thumb:hover {
      background: #555;
    }    
  }
}

.nav-item-side {
  background-color: $branco;
  border: 0;
  &.active {
    font-weight: 600;
  }

  &:hover {
    background-color: #00000011;
    transition: 0.4s;
  }

  @media (max-width: 450px) {
    font-size: 24px;
  }
}

.br-sm {
  @media (max-width: 1199px) {
    border-radius: 4px !important;
  }
}

.brand-section {
  background-color: $vermelho;
  width: 40vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  & > a {
    cursor: pointer;

    & > img {
      max-width: 230px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.form-login-section {
  width: 60vw;
  height: 100vh;
  background-color: $branco;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;

  & > a {
    display: inline-block;
    position: absolute;
    top: 8%;
    width: 200px;

    & > img {
      display: inline-block;
      position: absolute;
      top: 8%;
      width: 200px;
  
      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  & > form {
    width: 100%;
    max-width: 394px;
    margin-top: 16px;
  }

  @media (max-width: 768px) {
    width: 100vw;
    padding: 14px;
  }
}

.login-title {
  color: #333;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
}

.login-subtitle {
  color: $cinza;
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0 32px;
  text-decoration: none;
}

.tres-pontinhos {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.login-recuperar-senha {
  color: $cinza;
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0 32px;
  text-decoration: none;
  background: transparent;
  border: 0;
  padding: 0;
  display: block;
  transition: 180ms color ease-in-out;

  &:hover {
    text-decoration: underline;
    color: $dourado;
  }

  &:focus {
    outline: 0;
  }
}

.login-input {
  border: 1px solid $cinza;
  height: 46px;
  background-color: transparent;
  border-radius: 4px;
  transition: border 0.3s;

  & + .login-input {
    margin-top: 8px;
  }

  & > input {
    font-weight: 500;
    padding-top: 1px;
    color: #62686f;

    &::placeholder {
      color: $cinza;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: #62686f;
    }
  }

  & > label {
    padding: 0 16px;

    & > svg {
      transition: color 0.3s;
    }
  }
}

.login-button {
  width: 100%;
  height: 46px;
  background-color: $dourado;
  border: 0;
  border-radius: 4px;
  color: $branco;
  font-weight: 700;
  margin-top: 16px;
  transition: background-color 0.2s;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: darken($color: $dourado, $amount: 5);
    color: $branco;
    text-decoration: none;
  }

  &:focus {
    outline: 0;
  }
}

.login-nuvoni {
  font-weight: 700;
  color: $vermelho;
  position: fixed;
  bottom: 10px;
  right: 20px;
  font-size: 18px;

  &:hover {
    color: $vermelho;
  }

  @media (max-height: 500px) {
    position: absolute;
  }
}

.radios {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  gap: 16px;
}

.custom-radio {
  width: 100%;
  height: 46px;
  background-color: transparent;
  color: $cinza;
  font-weight: 500;
  padding: 0;
  border: 0;
  border-radius: 4px;
  border: 1px solid $cinza;
  transition: 180ms border-color ease-in-out;

  &:focus {
    outline: 0;
  }

  label {
    color: $cinza;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;
    padding-top: 2px;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 180ms border ease-in-out;
    }

    p {
      margin-left: 16px;
      transition: 100ms color ease-in-out;
    }

    input {
      opacity: 0;
      width: 0;
      height: 0;

      & + span::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transition: 180ms transform ease-in-out, 100ms background-color ease-in-out;
        transform: scale(0);
        background-color: currentColor;
      }

      &:checked + span::before {
        transform: scale(1);
        background-color: $dourado;
      }

      &:checked + span {
        border: 1px solid $dourado;
      }

      &:checked + span + p {
        color: $dourado;
      }
    }
  }

  span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid currentColor;
    transform: translateY(-0.05em);
  }
}

.radio-selected {
  border-color: $dourado;
}

.login-email-icon {
  margin-bottom: 16px;
  align-self: center;
  transform: translateX(-7px);
}

.subtitle-mail {
  margin-bottom: 0;
  margin-top: 4px;
}

.login-back {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 34px;
  border: 0;
  background-color: transparent;
  padding: 0;

  &:focus {
    outline: 0;
  }

  transform: translate(100%, 50%);

  @media (max-width: 450px) {
    transform: translate(50%, 50%);
  }
}

.header-admin {
  height: 86px;

  h1 {
    color: black;
    margin: 0 0 0 16px;
    font-size: 28px;
    font-weight: 500;

    strong {
      color: $dourado;
      margin-left: 4px;
    }
  }

  @media (max-width: 500px) {
    padding-top: 4px;

    h1 {
      display: flex;
      font-size: 20px;
      margin: 0 0 0 16px;
    }
  }
}

.admin-hello-message {
  margin-bottom: 20px;
}

.logo-vermelho {
  margin-right: 10px;
  margin-bottom: 6px;
}

.new-header-admin {
  width: 100%;
  padding-top: 28px;
  display: flex;
  align-items: center;

  div {
    justify-content: space-between;
    display: flex;
    align-items: center;

    h1 {
      color: $preto;
      margin: 0 0 0 16px;
      font-size: 28px;
      font-weight: 400;

      strong {
        color: $dourado;
      }
    }
  }

  @media (max-width: 450px) {
    padding-top: 24px;

    h1 {
      display: none;
    }
  }
}

.socio-header {
  width: 100%;
  padding-top: 32px;
  display: flex;
  align-items: center;

  div {
    justify-content: space-between;
    display: flex;
    align-items: center;

    h1 {
      color: $branco;
      margin: 0 0 0 16px;
      font-size: 28px;
      font-weight: 400;

      strong {
        color: $dourado;
      }
    }
  }

  @media (max-width: 450px) {
    padding-top: 24px;

    h1 {
      display: none;
    }
  }
}

.hamburger-socio {
  padding-right: 0;
  position: unset;

  .hamburger-box {
    transform: rotate(180deg);
  }

  .hamburger-inner {
    background-color: $branco;
    width: 30px;
    height: 3px;
    transition: all 0.4s;
  }

  .hamburger-inner::before {
    transition: all 0.4s;
    background-color: $branco;
    width: 25px;
    height: 3px;
  }

  .hamburger-inner::after {
    transition: all 0.4s;
    background-color: $branco;
    width: 20px;
    height: 3px;
  }
}

.hamburger-admin {
  padding-right: 0;
  position: unset;

  .hamburger-box {
    transform: rotate(180deg);
  }

  .hamburger-inner {
    background-color: $preto;
    width: 30px;
    height: 3px;
    transition: all 0.4s;
  }

  .hamburger-inner::before {
    transition: all 0.4s;
    background-color: $preto;
    width: 25px;
    height: 3px;
  }

  .hamburger-inner::after {
    transition: all 0.4s;
    background-color: $preto;
    width: 20px;
    height: 3px;
  }
}

.hamburger-black {
  .hamburger-inner {
    background: #333;
  }

  .hamburger-inner::before {
    background: #333;
    width: 30px;
  }

  .hamburger-inner::after {
    background: #333;
    width: 30px;
  }
}

.socio-wrapper {
  min-height: 100vh;
  background: linear-gradient(180deg, $vermelho 250px, $quaseBranco 250px);
  padding-bottom: 24px;
  overflow-x: hidden; 

  @media (max-width: 450px) {
    background: linear-gradient(180deg, $vermelho 268px, $quaseBranco 268px);
  }
}

.socio-wrapper-secondary {
  min-height: 100vh;
  padding-bottom: 24px;
  background-color: $quaseBranco;
}

.socio-header-secondary {
  padding-bottom: 32px;
  background-color: $vermelho;
}

.socio-container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.admin-container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.socio-header {
  width: 100%;
  padding-top: 32px;
  display: flex;
  align-items: center;

  div {
    justify-content: space-between;
    display: flex;
    align-items: center;

    h1 {
      color: $branco;
      margin: 0 0 0 16px;
      font-size: 28px;
      font-weight: 400;

      strong {
        color: $dourado;
      }
    }
  }

  @media (max-width: 450px) {
    padding-top: 24px;

    h1 {
      display: none;
    }
  }
}

.socio-cards-list {
  width: 100%;
  max-width: 1140px;
  margin: 64px auto 0;
  padding: 0 15px 18px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  list-style: none;
  display: flex;

  .cards-list-space {
    width: 15px;
    min-width: 15px;
    height: 100%;
    min-height: 136px;
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    margin-left: 15px;
    width: 350px;
    min-width: 300px;
    min-height: 136px;
    padding: 24px;
    border-radius: 4px;
    background-color: $branco;
    box-shadow: 0px 4px 16px rgba(172, 183, 193, 0.3);
    position: relative;

    & + li {
      margin-left: 30px;
    }

    span {
      color: $cinza;
    }

    div {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: baseline;

      a {
        font-size: 14px;
        color: $cinza;
        text-decoration: underline;
      }
    }

    h1 {
      font-weight: 500;
      font-size: 42px;
      color: $socioTextoEscuro;
      margin: 14px 0 0 0;
    }

    span.icon {
      font-size: 32px;
      position: absolute;
      top: 18px;
      right: 24px;
    }
  }

  @media (max-width: 975px) {
    .cards-list-space {
      display: block;
    }

    li + li {
      margin-left: 15px;
    }
  }

  @media (max-width: 500px) {
    .cards-list-space {
      min-height: 0
    }
  }

  @media (max-width: 450px) {
    margin-top: 24px;
  }

  @media (max-width: 1052px) {
    li {

      div > h1 {
        font-size: 30px;
      } 
    }
  }

  @media (max-width: 975px) {
    li {
      padding: 12px;
      max-width: 120px !important;
      min-width: 30%;
      max-height: 60px !important;

      div > h1 {
        font-size: 30px;
      } 

      h1 {
        font-size: 30px;
      } 
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;

    li {
      min-width: 90%;
      margin-top: 15px;
    }
  }
}

.label-status-container {
  display: flex;
  align-items: center;

  .label-status-info {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;

    svg {
      font-size: 18px;
    }
  }

  .label-status {
    height: 24px;
    width: 86px;
    font-size: 14px;
    color: $vermelhoVivo;
    background-color: #f8cbd4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-left: 8px;
  }

  .label-status-aprovado {
    color: $verde;
    background-color: #cbebe4;
  }
}

.label-status-container-compareceu {
  display: flex;
  align-items: center;
  width: 114px;
  margin-left: auto;

  .label-status-info-compareceu {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;

    svg {
      font-size: 18px;
    }
  }

  .label-status {
    height: 24px;
    width: 86px;
    font-size: 9px;
    color: $vermelhoVivo;
    background-color: #f8cbd4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
  }

  .label-status-compareceu {
    color: $verde;
    background-color: #cbebe4;
    font-size: 10px;
  }
}

.socio-recorrencia-section,
.admin-recorrencia-section {
  width: 100%;
  margin-top: 46px;

  h2 {
    color: $socioTextoEscuro;
    font-weight: 600;
    font-size: 24px;
    margin: 0;
  }

  ul {
    width: 100%;
    padding: 0;
    list-style: none;
    margin-top: 24px;
    margin-bottom: 0;

    li {
      height: 46px;
      background-color: $branco;
      border-radius: 4px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      position: relative;

      & + li {
        margin-top: 8px;
      }

      span {
        font-size: 18px;
        color: $socioTextoEscuro;

        @media (max-width: 500px) {
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }

      .socio-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        flex: 1;
      }

      @media (max-width: 450px) {
        & {
          height: auto;
          padding: 18px 24px;
        }

        span {
          text-align: unset;
        }

        .socio-list-label-status {
          position: absolute;
          top: 22px;
          right: 24px;
        }

        #valor {
          grid-area: valor;
          font-size: 22px;
        }

        #forma {
          grid-area: forma;
          font-size: 14px;
          color: #969cb2;
        }

        #date {
          grid-area: date;
          text-align: end;
          font-size: 14px;
          color: #969cb2;
        }

        .socio-grid {
          grid-template-columns: repeat(2, 1fr);
          grid-auto-flow: dense;
          grid-template-areas:
            'valor .'
            'forma date';
          grid-row-gap: 20px;
          row-gap: 20px;
        }
      }

      @media (max-width: 350px) {
        #date {
          text-align: justify;
        }

        #label-status {
          top: 32%;
          right: auto;
          left: 16px;

          .label-status-info {
            display: none;
          }
        }

        .socio-grid {
          grid-template-columns: repeat(4, 1fr);
          grid-auto-flow: dense;
          grid-template-areas:
            'valor'
            'status'
            'forma'
            'date';
          grid-row-gap: 20px;
          row-gap: 20px;
        }
      }
    }
  }

  @media (max-width: 450px) {
    margin-top: 14px;
  }
}

.socio-nome-mobile {
  margin: 32px 0 0;
  display: none;
  font-size: 28px;
  font-weight: 400;
  color: $branco;

  strong {
    color: $dourado;
  }

  @media (max-width: 450px) {
    display: block;
  }
}

.socio-menu-side {
  transition: right cubic-bezier(0.75, -0.5, 0, 1.25) 0.5s;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0.2);
  display: block !important;
  width: 150vw;

  & > div {
    margin-left: auto;
    width: 70vw;
    background-color: $branco;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 551px) {
    & > div > .menu-side-content {
      transform: translate(0, 0);
    }
  }

  @media (max-width: 550px) {
    & > div {
      width: 100vw;
    }
  }
}

.no-scroll {
  overflow: hidden;
}

.socio-sair {
  width: 100%;
  padding: 8px 16px;
  background-color: transparent;
  border: 0;
  text-align: start;
  margin-top: 16px;
  color: $vermelhoVivo;
  font-size: 22px;
  transition: 0.4s;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: $vermelhoVivo;
    color: #ffffff;
  }
}

.admin-sair {
  width: 100%;
  padding: 8px 16px;
  background-color: transparent;
  border: 0;
  text-align: start;
  margin-top: 16px;
  color: $vermelhoVivo;
  font-size: 22px;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: $vermelhoVivo;
    color: #fff;
  }
}

.socio-admin {
  width: 100%;
  padding: 8px 16px;
  background-color: transparent;
  border: 0;
  text-align: start;
  margin-top: 16px;
  color: #d69947;
  font-size: 22px;
  transition: 0.4s;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: #d69947;
    color: #ffffff;
  }
}

.admin-socio {
  width: 100%;
  padding: 8px 16px;
  background-color: transparent;
  border: 0;
  text-align: start;
  margin-top: 16px;
  color: #d69947;
  font-size: 22px;

  &:focus {
    outline: 0;
  }

  &:hover {
    background: #d69947;
    color: #ffffff;
  }
}

.ajustes-plano-section {
  margin-top: 32px;

  h1 {
    margin: 0;
    color: $socioTextoEscuro;
    font-size: 24px;
    font-weight: 600;
  }
}

.socio-planos-list {
  width: 100%;
  max-width: 1140px;
  margin: 24px auto 0;
  padding: 0 15px 18px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  list-style: none;
  display: flex;

  .cards-list-space {
    width: 15px;
    min-width: 15px;
    height: 100%;
    min-height: 136px;
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  a,
  .plano-selected {
    width: 350px;
    min-width: 300px;
    min-height: 136px;
    padding: 24px 24px 24px 60px;
    border-radius: 4px;
    background-color: $branco;
    box-shadow: 0px 4px 16px rgba(172, 183, 193, 0.3);
    position: relative;
    text-decoration: none;
    border: 2px solid $cinza;

    & > div {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 22px;
      left: 24px;
      border: 2px solid $cinza;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h1 {
      font-weight: 500;
      font-size: 42px;
      color: $cinza;
      margin: 14px 0 0 0;
    }

    &.plano-selected {
      border: 2px solid $dourado;
      cursor: pointer;

      h1 {
        color: $socioTextoEscuro;
      }

      & > div {
        border: 2px solid $dourado;

        & > div {
          width: 14px;
          height: 14px;
          background-color: $dourado;
          border-radius: 50%;
        }
      }
    }

    & + a,
    & + .plano-selected,
    & + a {
      margin-left: 30px;
    }

    span {
      color: $cinza;
    }

    svg {
      font-size: 32px;
      position: absolute;
      top: 18px;
      right: 24px;
    }
  }

  @media (max-width: 975px) {
    .cards-list-space {
      display: block;
    }

    a + a,
    a + .plano-selected,
    .plano-selected + a {
      margin-left: 15px;
    }
  }

  @media (max-width: 450px) {
    margin-top: 24px;
  }
}

.ajustes-geral-section {
  margin-top: 32px;

  h1 {
    margin: 0;
    color: $socioTextoEscuro;
    font-size: 24px;
    font-weight: 600;
  }

  .socio-ajustes-list {
    margin-top: 24px;

    .socio-ajustes-item {
      width: 100%;
      background-color: $branco;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 24px;
      border-radius: 4px;
      opacity: 0.6;
      cursor: not-allowed;
      position: relative;

      & + .socio-ajustes-item {
        margin-top: 16px;
      }

      & > div {
        margin-right: 10px;
      }

      h2 {
        color: $socioTextoEscuro;
        font-size: 20px;
        font-weight: 600;
      }

      p {
        font-weight: 600;
        font-size: 16px;
        color: $cinza;
      }

      span.icon {
        font-size: 26px;
      }

      span.em-breve {
        font-size: 18px;
        font-weight: 600;
        color: $cinza;
        margin-left: auto;
        white-space: nowrap;
      }
    }

    a.socio-ajustes-item {
      opacity: 1;
      transition: transform 0.125s ease-out;
      cursor: pointer;
      box-shadow: 0px 4px 16px rgba(172, 183, 193, 0.2);

      &:hover {
        transform: translateX(10px);
      }
    }

    span.icon {
      margin-left: auto;
    }
  }
}

.cor-vermelho {
  color: $vermelhoVivo
}

.jogadorCard {
  box-shadow: 10px 0 12px -2px #888;
  &:hover {
    opacity: 0.8;
    transition: 0.5s;
  }
}

.tt-upper {
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap
}

.boxshadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.inlineBlock {
  display: inline-block;
}

.socio-risk-section {
  margin-top: 64px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
    color: $vermelhoVivo;
    margin: 0;
  }

  hr {
    width: 99%;
    margin: 16px 0 32px;
  }

  button {
    width: 100%;
    border: 2px solid $vermelhoVivo;
    color: $vermelhoVivo;
    height: 46px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;
    outline: 0;
    border-radius: 4px;
    background-color: transparent;

    svg {
      margin-right: 8px;
      font-size: 20px;
    }

    &:hover {
      color: $branco;
      background-color: $vermelhoVivo;
    }
  }

  .valid {
    border: 2px solid $verde;
    color: $verde;

    &:hover {
      background-color: $verde;
    }
  }
}

section.socio-planos {
  margin-top: 32px;

  & > form > div > h1 {
    color: $socioTextoEscuro;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
  }

  .planos-list {
    width: 100%;
    display: flex;
    margin-top: 24px;
    flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
    justify-content: center;

    button {
      background-color: $branco;
      border: 2px solid $cinza;
      min-height: 387px;
      width: 100%;
      max-width: 350px;
      border-radius: 4px;
      position: relative;
      padding: 0;
      color: $cinza;

      @media (max-width: 1140px) {
        max-width: calc(50% - 15px);
      }

      @media (max-width: 690px) {
        max-width: 100%;
      }

      h1 {
        color: $cinza;
      }

      &:focus {
        outline: 0;
      }

      label {
        padding: 24px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        margin-bottom: 0;
        text-align: start;
        display: flex;
        flex-direction: column;

        svg {
          position: absolute;
          top: 18px;
          right: 24px;
          font-size: 32px;
        }

        span.plano-label {
          font-size: 16px;
          color: $cinza;
          margin-left: 32px;
        }

        span.bolinha {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 22px;
          left: 24px;
          transition: 180ms border ease-in-out;
        }

        p {
          margin-left: 16px;
          transition: 100ms color ease-in-out;
        }

        input {
          opacity: 0;
          width: 0;
          height: 0;

          @keyframes bolinhas {
            0% {
              transform: scale(0);
            }

            100% {
              transform: scale(1);
            }
          }

          @keyframes bordaBolinha {
            0% {
              border: 2px solid $cinza;
            }

            100% {
              border: 2px solid $dourado;
            }
          }

          & + span.bolinha::before {
            content: '';
            width: 14px;
            height: 14px;
            border-radius: 50%;
            transition: 180ms transform ease-in-out, 100ms background-color ease-in-out;
            transform: scale(0);
            background-color: $cinza;
          }

          &:checked + span.bolinha::before {
            transform: scale(1);
            background-color: $dourado;
            animation: bolinhas 180ms;
          }

          &:checked + span.bolinha {
            border: 2px solid $dourado;
            animation: bordaBolinha 180ms;
          }

          &:checked + span.bolinha + p {
            color: $dourado;
          }
        }
      }

      span.bolinha {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid $cinza;
        transform: translateY(-0.05em);
      }

      .socio-plano-item-container {
        margin-top: 14px;
        margin-bottom: 14px;

        h1 {
          font-size: 42px;
          margin: 0;
          font-weight: 500;
        }
      }

      .socio-plano-item-benefits {
        padding-left: 24px;
        margin: 0;
        margin-top: 16px;

        li {
          & + li {
            margin-top: 8px;
          }
        }
      }

      .socio-plano-item-price {
        width: 100%;
        font-weight: 600;
        font-size: 18px;
        text-align: center;
        margin-bottom: 0;
        margin-top: auto;
      }

      .socio-plano-item-cart {
        margin: 0;
        text-align: center;
        font-size: 12px;
        margin-top: 2px;
      }

      &.is-active {
        border-color: $dourado;
        color: $socioTextoEscuro;

        h1 {
          color: $socioTextoEscuro;
        }
      }
    }
  }
}

.socio-warnings {
  margin-top: 16px;
  font-size: 14px;
  color: $cinza;

  p + p {
    margin-top: 2px;
  }
}

.button-yellow {
  color: $branco;
  padding: 16px 32px;
  display: block;
  color: #fff;
  font-weight: 700;
  background-color: $dourado;
  border: 0;
  transition: background-color 0.2s;
  border-radius: 4px;
  line-height: 16px;

  &:not(:disabled):hover {
    background-color: darken($color: $dourado, $amount: 5);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 70%;
  }

  &:focus {
    outline: 0;
  }

  @media (max-width: 575px) {
    width: 100%;
  }
}

.button-cancel {
  padding: 16px 32px;
  display: block;
  color: #fff;
  font-weight: 700;
  border: 2px solid $cinza;
  color: $cinza;
  background-color: transparent;
  margin-right: 24px;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  line-height: 16px;
  text-align: center;

  &:hover {
    text-decoration: none;
    color: $cinza;
  }

  // &:not(:disabled):hover {
  //   color: #fff;
  //   background-color: $cinza;
  // }

  &:disabled {
    cursor: not-allowed;
    opacity: 70%;
  }

  &:focus {
    outline: 0;
  }

  @media (max-width: 575px) {
    width: 100%;
    margin-right: 0;
    margin-top: 8px;
  }
}

.alterar-cartao {
  margin-top: 32px;

  h1 {
    color: $socioTextoEscuro;
    font-size: 24px;
    margin: 0;
    font-weight: 600;
  }

  .buttons-group {
    margin-top: 24px;
  }

  & > div {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap-reverse;
    justify-content: center;
    background-color: $branco;
    margin-top: 24px;
    border-radius: 4px;
    padding: 0;

    @media (max-width: 900px) {
      padding-top: 24px;
    }

    @media (max-width: 340px) {
      padding-top: 0;
    }
  }

  form {
    width: 100%;
    max-width: calc(50% - 32px);
    padding: 24px;
    background-color: $branco;
    border-radius: 4px;

    @media (max-width: 900px) {
      max-width: 100%;
      padding: 24px;
    }

    .label-input {
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      color: #717171;
      margin-bottom: 0;
    }

    & > .label-input + .label-input {
      margin-top: 16px;
    }

    & > .double-input {
      margin-top: 16px;
      display: flex;
      grid-gap: 16px;
      gap: 16px;

      .login-input {
        margin-top: 0;
      }

      // @media (max-width: 425px) {
      //   flex-wrap: wrap;
      // }
    }
  }
}

@keyframes animateScrollToTop {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.btn-scroll-to-top {
  position: fixed;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #343a40;
  border: 0;
  bottom: 20px;
  right: 10px;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  color: $branco;
  font-size: 16px;

  animation: animateScrollToTop 0.3s;
}

.modal-warning {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;

  & > div {
    width: 100%;
    background-color: $branco;
    padding: 18px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 450px;

    p {
      font-size: 16px;
      color: $socioTextoEscuro;
      font-weight: 500;
    }

    svg {
      font-size: 62px;
      color: $dourado;
    }

    button {
      width: 100%;
      height: 40px;
      border: 0;
      font-weight: 600;
      margin-top: 16px;
      border-radius: 4px;
      color: $branco;
      transition: all 0.2s;

      & + button {
        margin-top: 8px;
      }

      &:focus {
        outline: 0;
      }
    }

    .continuar {
      background-color: $dourado;

      &:hover {
        background-color: lighten($color: $dourado, $amount: 5);
      }
    }

    .cancelar {
      background-color: transparent;
      border: 2px solid $cinza;
      color: $cinza;

      // &:hover {
      //   color: #fff;
      //   background-color: $cinza;
      // }
    }
  }
}

.alterar-dados-form {
  margin-top: 32px;

  h1 {
    font-size: 24px;
    font-weight: 600;
    color: $socioTextoEscuro;
    margin: 0;
  }

  form {
    margin-top: 24px;
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    background-color: $branco;
    grid-gap: 24px;
    gap: 24px;
    border-radius: 4px;
    justify-content: flex-end;
  }

  .label-input {
    width: 100%;
    max-width: calc(50% - 12px);
    font-size: 16px;
    font-weight: 600;
    color: #717171;
    margin-bottom: 0;
  }

  .label-input-max {
    max-width: 100%;
  }

  @media (max-width: 650px) {
    .label-input {
      max-width: 100%;
    }
  }
}

.buttons-group {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
}

.login-select-input {
  border: 1px solid $cinza;
  height: 46px;
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  transition: border 0.3s;
  overflow: hidden;

  & > label {
    display: flex;
    margin: 0;
    padding: 0 16px;
    align-items: center;
  }

  & > select {
    font-weight: 600;
    color: $cinza;
    border: 0;
    height: 100%;
    width: 100%;
    margin-right: 8px;

    &:focus-visible {
      outline: none;
    }
  }

  & > label {
    padding: 0 16px;

    & > svg {
      transition: color 0.3s;
    }
  }
}

.socio-input-error {
  border: 1px solid $vermelhoVivo;

  & > input {
    color: $vermelhoVivo;

    &::placeholder {
      color: lighten($color: $vermelhoVivo, $amount: 38);
    }
  }

  & > label > svg {
    color: $vermelhoVivo;
  }
}

.badge-status {
  padding: 8px 24px;
  border-radius: 4px;
  font-size: 22px;
  background-color: $cinza;
  display: inline-block;

  &.badge-cancelado {
    background-color: rgba($color: $vermelhoVivo, $alpha: 0.25);
    color: $vermelhoVivo;
  }

  &.badge-inativo {
    background-color: rgba($color: $amarelo, $alpha: 0.3);
    color: $amarelo;
  }

  &.badge-ativo {
    background-color: rgba($color: $verde, $alpha: 0.25);
    color: $verde;
  }
}

.p-4-15 {
  padding: 24px calc(24px - 15px);
}

.px-15 {
  padding: 0 15px;
}

.admin-recorrencia-section {
  margin-top: 0;

  ul {
    margin-top: 16px;
    padding: 0 15px;

    li {
      background-color: $quaseBranco;

      span {
        color: #333;
      }
    }
  }

  @media (max-width: 450px) {
    ul li {
      padding: 14px 20px;

      #valor {
        font-size: 18px;
      }
    }

    ul li .socio-list-label-status {
      position: absolute;
      top: 15px;
      right: 24px;
    }
  }
}

.main-header {
  background-color: $vermelho;
  width: 100%;
  height: 80px;
  align-items: center;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__sponsors {
    display: flex;
    align-items: center;

    @media (max-width: 319px) {
      width: 100%;
      justify-content: space-between;
    }

    &__img {
      & + & {
        margin-left: 28px;
      }
    }
  }

  &__short-nav {
    display: flex;
    align-items: center;

    &__link {
      font-weight: bold;
      color: $branco;
      font-size: 14px;
      padding: 12px 0;

      & + & {
        margin-left: 24px;
      }

      &:hover {
        color: $branco;
        text-decoration: none;
      }

      &:last-of-type {
        margin-right: 60px;
      }

      @media (max-width: 829px) {
        display: none;
      }
    }

    &__socials {
      &__link {
        & + & {
          margin-left: 10px;
        }
      }

      @media (max-width: 319px) {
        display: none;
      }
    }
  }
}

.button {
  border-radius: 500px;
  background-color: $dourado;
  border: 0;
  font-size: 14px;
  color: $branco;
  font-weight: 700;
  outline: 0;
  transition: background-color 0.3s;
  height: fit-content;
  width: fit-content;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid $dourado;

  &:hover {
    text-decoration: none;
    color: $branco;
    background-color: lighten($color: $dourado, $amount: 5%);
  }

  &--small {
    @extend .button;
    padding: 12px 16px;
  }

  &--xsmall {
    @extend .button;
    padding: 8px 16px;
  }

  &--large {
    @extend .button;
    padding: 16px 32px;
  }

  &--xlarge {
    @extend .button;
    padding: 16px 56px;
  }

  &--white {
    @extend .button;
    color: $dourado;
    background-color: $branco;

    &:hover {
      color: $dourado;
      background-color: lighten($color: $branco, $amount: 5%);
    }

    &--xlarge {
      @extend .button--white;
      padding: 16px 56px;

      &--responsive {
        @extend .button--white--xlarge;
        @media (max-width: 937px) {
          padding: 16px 32px;
        }
      }
    }
  }

  &--outline {
    @extend .button;
    color: $dourado;
    background-color: transparent;
    border: 1px solid $dourado;

    &:hover {
      color: $dourado;
      background-color: rgba($color: $preto, $alpha: 0.03);
    }

    &--xsmall {
      @extend .button--outline;
      padding: 8px 16px;
    }

    &--large {
      @extend .button--outline;
      padding: 16px 32px;
    }
  }
}

.follow-button {
  padding: 6px 12px;
  background-color: transparent;
  border: 0;
  border-radius: 500px;
  color: $branco;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
    color: $branco;
  }

  &--instagram {
    @extend .follow-button;

    background: linear-gradient(252.61deg, #a129a8 0%, #ec4747 50%, #fdb215 100%);
  }

  &--youtube {
    @extend .follow-button;

    background-color: #f44336;
  }

  &--facebook {
    @extend .follow-button;

    background-color: #1976d2;
  }
}

.main-nav {
  height: 90px;
  padding: 32px 0;
  margin-bottom: 50px;
  font-size: 14px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: $vermelho;
  transition: box-shadow 0.3s;

  & .active {
    font-weight: 700;
  }

  @media (max-width: 1199px) {
    font-size: 20px;
  }

  @media (max-width: 425px) {
    padding: 16px 0;
  }

  &__brand {
    transition: height 0.2s;

    @media (max-width: 375px) {
      height: 60px;
    }
  }

  &__container {
    max-width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__nav {
    display: flex;
    align-items: center;

    @media (max-width: 1199px) {
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background-color: $branco;
      z-index: 99;
      align-items: flex-start;
      flex-direction: column;
      padding: 80px 48px;
      transform: translateX(100vw);
      transition: transform 0.5s;
      overflow-y: auto;

      &.is-open {
        transform: translateX(0);
      }

      @media (min-height: 730px) {
        justify-content: center;
      }
    }

    & > div {
      display: flex;
      width: 100%;

      @media (max-width: 1199px) {
        flex-direction: column;
      }
    }

    &__button {
      margin-left: 48px;

      @media (max-width: 1199px) {
        margin-left: 0;
        // margin-top: 24px;
        text-align: center;
      }

      @media (max-width: 500px) {
        padding: 8px 22px;
        font-size: 10px;
      }
    }

    &__link {
      margin-right: auto;
      border: 0;
      color: $branco;
      background-color: transparent;
      padding: 12px 6px;
      position: relative;
      overflow: hidden;
      transition: color 0.3s;
      display: inline-block;
      line-height: 1.5;
      width: fit-content;
      height: fit-content;

      @media (max-width: 1199px) {
        text-align: start;
        padding-left: 0;
        color: $preto;

        &:focus {
          color: $dourado;
          text-decoration: none;
        }
      }

      & + &,
      & + .has-submenu,
      .has-submenu + & {
        margin-left: 32px;

        @media (max-width: 1199px) {
          margin-left: 0;
          margin-top: 5px;
          margin-right: auto;
        }
      }

      &::after {
        content: '';
        transition: transform 0.3s;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $dourado;
        transform: translateX(-50%) scaleX(0);
      }

      &--bold {
        @extend .main-nav__nav__link;
        font-weight: 700;
      }

      &:hover {
        text-decoration: none;
      }

      @media (max-width: 768px) {
        &:focus {
          color: $dourado;
          text-decoration: none;

          &::after {
            transform: translateX(-50%) scaleX(2);
          }
        }
      }

      @media (min-width: 769px) {
        &:hover {
          color: $dourado;

          &::after {
            transform: translateX(-50%) scaleX(2);
          }
        }
      }
    }

    &__hamburger {
      display: none;

      @media (max-width: 1199px) {
        display: flex;
        position: absolute;
        right: 0;
        top: 15px;
      }
    }

    &__additional-menu {
      display: none;
      width: 100%;

      @media (max-width: 1199px) {
        display: flex;
        flex-direction: column;
        margin-top: 5px;

        & > .main-nav__nav__link {
          width: fit-content;
        }
      }

      &__socials {
        margin-top: 12px;
        padding-top: 12px;
        width: 100%;
        border-top: 1px solid #eee;

        &__icon {
          width: 30px;
        }
      }
    }
  }

  &__hamburger {
    display: none;
    padding-right: 0;
    background-color: transparent;
    border: 0;

    @media (max-width: 1199px) {
      display: block;
    }
  }
}

.scrolled {
  box-shadow: 0px 8px 24px rgba(172, 183, 193, 0.2);

  .main-nav__brand {
    height: 60px;
  }
}

.has-submenu {
  position: relative;
  width: fit-content;
  margin-right: auto;
  & + .has-submenu {
    margin-left: 32px;

    @media (max-width: 1199px) {
      margin-left: 0;
      margin-top: 5px;
    }
    &.admin {
      margin-left: 0;
    }
  }

  & + .main-nav__nav__link {
    margin-left: 32px;

    @media (max-width: 1199px) {
      margin-left: 0;
      margin-top: 5px;
    }
  }

  &:hover,
  &:focus-within {
    & > .has-submenu__submenu {
      max-height: 300px;
      left: 0;
    }

    & > .has-submenu__button--bold {
      color: $dourado;

      &::after {
        transform: translateX(-50%) scaleX(2);
      }
    }
  }

  &__button {
    @extend .main-nav__nav__link;

    &:focus + .has-submenu__submenu,
    &:hover + .has-submenu__submenu {
      max-height: 300px;
      left: 0;
    }

    &--bold {
      @extend .has-submenu__button;
      font-weight: 700;
    }
  }

  &__submenu {
    max-height: 0;
    overflow: hidden;
    background-color: $preto;
    position: absolute;
    display: block;
    transition: max-height ease-in-out 0.5s, overflow 10s;
    white-space: nowrap;
    z-index: 9;

    @media (max-width: 1199px) {
      background-color: $branco;
      position: unset;
    }

    & > div {
      padding: 32px 40px 32px 32px;

      @media (max-width: 1199px) {
        width: 100%;
        padding: 16px 0px 0px 8px;
      }
    }

    &__link {
      display: block;
      font-weight: 300;
      color: $branco;
      padding: 0 16px;

      @media (max-width: 1199px) {
        color: $preto;
      }

      &:hover {
        color: $branco;

        @media (max-width: 1199px) {
          color: $preto;
        }
      }

      & + & {
        margin-top: 5px;
      }
    }
  }
}

.main-hero {
  max-height: 555px;
  width: 100%;
  position: relative;
  margin-bottom: 50px;

  @media (max-width: 1199px) {
    padding: 0;
  }

  &__info {
    position: absolute;
    padding: 0 6% 10% 6%;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: start;

    @media (max-width: 499px) {
      align-items: flex-end;
      flex-direction: row;
      justify-content: space-between;
    }

    &__title {
      font-size: clamp(100%, calc(1rem + 2vw), 46px);
      font-weight: 700;
      margin: 0;
      margin-bottom: 16px;

      @media (max-width: 499px) {
        margin-bottom: 0;
      }
    }

    &__button {
      @media (max-width: 399px) {
        display: none;
      }

      &__text {
        font-size: clamp(10px, calc(10px + 0.5vw), 14px) !important;
      }
    }
  }
}

.main-banner {
  max-height: 555px;
  width: 100%;
  position: relative;
  margin-bottom: 50px;

  button {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: $vermelho;
    color: $branco;
    font-size: 14px;
    font-weight: 700;
    padding: 16px 52px;
    border: none;
    cursor: pointer;
    border-radius: 500px;
    width: fit-content;
    transition: 0.6s background-color;

    &:hover {
      transition: 0.6s background-color;
      text-decoration: none;
      background-color: lighten($color: $vermelho, $amount: 5);
    }

    &--small {
      @extend .button;
      padding: 12px 16px;
    }

    &--xsmall {
      @extend .button;
      padding: 8px 16px;
    }

    &--large {
      @extend .button;
      padding: 16px 32px;
    }

    &--xlarge {
      @extend .button;
      padding: 16px 56px;
    }
    
    @media (max-width: 399px) {
      display: none;
    }

    @media (max-width: 500px) {
      display: none;
    }
  }
}

@supports (display: grid) {
  .next-game {
    color: $preto;

    &__title {
      margin: 0 0 32px 0;
      font-size: 24px;
      font-weight: 700;

      @media (max-width: 600px) {
        font-size: 20px;
      }
    }

    &__checkin,
    &__buy-ticket {
      width: 100%;
    }

    &__info {
      display: grid;
      grid-template-columns: auto auto;
      grid-gap: 24px 46px;
      gap: 24px 46px;
      width: 100%;

      @media (max-width: 1199px) {
        grid-column-gap: 2.75vw;
        column-gap: 2.75vw;
      }

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
        justify-items: center;
        align-items: center;
        grid-column-gap: unset;
        column-gap: unset;
      }

      @media (max-width: 400px) {
        // justify-items: normal;
      }

      &__versus {
        pointer-events: none;
        display: flex;
        align-items: center;

        @media (max-width: 900px) {
          width: fit-content;
        }

        &__icon {
          font-size: 32px;
          font-weight: 700;
          margin: 0 auto;
          line-height: 39px;

          @media (max-width: 900px) {
            margin: 0 24px;
          }
        }

        &__team-logo {
          height: 70px;

          @media (max-width: 375px) {
            height: 60px;
          }
        }
      }

      &__details {
        text-align: start;
        font-size: 14px;
        line-height: 18px;

        @media (max-width: 900px) {
          width: fit-content;
        }

        & > span {
          display: block;
        }

        &__league {
          font-weight: 700;
          color: $dourado;
        }

        &__teams {
          font-weight: 700;
        }
      }
    }
  }
}

.latest-news {
  color: $preto;
  font-size: 12px;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    margin-top: 50px;
  }

  &__header {
    margin-bottom: 32px;
  }

  &__view-all {
    font-weight: 700;
    font-size: 14px;
    color: $vermelho;
    text-decoration: underline;

    &:hover {
      color: $vermelho;
    }
  }

  &__title {
    margin: 0;
    font-size: 24px;
    font-weight: 700;

    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  &__empty {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: center;
    background-color: #f0f0f0;
    padding: 32px 8px;
    height: 100%;

    &__text {
      font-size: 14px;
      color: #a0a0a0;
      text-align: center;
      max-width: 75%;
      margin-top: 8px;

      @media (max-width: 410px) {
        max-width: 100%;
      }
    }

    &__img {
      width: 150px;
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

.small-banner {
  width: 100%;
  margin-top: 40px;
}

.large-banner {
  width: 100%;
  margin-top: 50px;
  object-fit: cover;
  background-color: $vermelho;

  @media (max-width: 425px) {
    margin-top: 32px;
  }
}

.landing-sponsors {
  width: 100%;
  margin-top: 60px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 425px) {
    margin-top: 32px;
  }

  &__main {
    display: flex;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    pointer-events: none;

    &__item {
      width: 170px;
      height: 100px;

      @media (max-width: 390px) {
        width: auto;
        height: auto;
      }

      & + & {
        margin-left: 24px;

        @media (max-width: 390px) {
          margin-left: 0;
        }
      }

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__slider {
    padding: 0;
    height: fit-content;
    margin-left: auto;
    list-style: none;
    display: flex;
    flex: 1;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    margin-bottom: 0;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__slide {
      height: 72px;
      flex: none;
      display: flex;
      justify-content: center;
      width: 20%;
      scroll-snap-align: start;
      pointer-events: none;

      @media (max-width: 1199px) {
        width: 25%;
      }

      @media (max-width: 920px) {
        width: 34%;
      }

      @media (max-width: 820px) {
        width: 50%;
      }

      @media (max-width: 768px) {
        width: 20%;
      }

      @media (max-width: 1199px) {
        width: 25%;
      }

      @media (max-width: 540px) {
        width: 33.33%;
      }

      @media (max-width: 440px) {
        width: 50%;
      }

      @media (max-width: 336px) {
        width: 100%;
      }

      & > * {
        height: 100%;
      }
    }
  }

  &__arrow {
    padding: 0 16px 0 16px;
    background-color: transparent;
    border: 0;
    color: $preto;
    font-size: 22px;

    &__left {
      @extend .landing-sponsors__arrow;

      @media (max-width: 768px) {
        margin-left: 0;
      }
    }
  }

  &__slider-container {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
}

@supports (display: grid) {
  .main-footer {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;

    &__separating-line {
      border-color: #f0f0f0;
      margin: 30px 0 0 0;
    }

    &__nav {
      color: $preto;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      font-size: 14px;
      max-width: 806px;
      text-align: start;

      @media (max-width: 670px) {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 40px;
        column-gap: 40px;
        grid-row-gap: 16px;
        row-gap: 16px;
      }

      @media (max-width: 405px) {
        grid-template-columns: 1fr 0.5fr;
        grid-column-gap: 0;
        column-gap: 0;
        grid-row-gap: 32px;
        row-gap: 32px;
      }

      #brand-link {
        @media (max-width: 670px) {
          max-width: fit-content;
          max-height: fit-content;
          transform: translate(60%, 60%);
        }

        @media (max-width: 568px) {
          max-width: fit-content;
          max-height: fit-content;
          transform: translate(40%, 60%);
        }
      }

      #brand {
        grid-area: 1 / 1;

        @media (max-width: 405px) {
          grid-area: 1 / 1;
          transform: translate(-0%, -60%);
        }
      }

      #links1 {
        grid-area: 1 / 2;

        @media (max-width: 670px) {
          grid-area: 1 / 2;
        }

        @media (max-width: 405px) {
          grid-area: 1 / 2;
        }
      }

      #links2 {
        grid-area: 1 / 3;

        @media (max-width: 670px) {
          grid-area: 1 / 3;
          margin-right: 4rem;
        }

        @media (max-width: 405px) {
          grid-area: 2 / 2;
        }
      }

      #links3 {
        @media (max-width: 670px) {
          grid-area: 2 / 3;
        }

        @media (max-width: 405px) {
          grid-area: 2 / 1;
          justify-self: center;
        }
      }

      #social {
        @media (max-width: 670px) {
          grid-area: 2 / 2;
        }

        @media (max-width: 405px) {
          grid-area: 3 / span 2;
        }
      }

      &__brand {
        height: 75px;
        width: fit-content;
      }

      &__links {
        &__link {
          display: block;
          color: $preto;
          font-weight: 700;
          width: fit-content;

          & + &,
          & + .main-footer__nav__links__category {
            margin-top: 16px;
          }

          &:hover {
            text-decoration: none;
            color: $preto;
          }
        }

        &__category {
          & > * {
            display: block;
          }

          &__label {
            font-weight: 700;
          }

          &__link {
            font-weight: 300;
            font-size: 12;
            color: $preto;

            &:hover {
              color: $preto;
            }
          }
        }
      }

      &__social-join {
        &__text {
          font-weight: 700;
        }

        &__icons {
          display: flex;
          margin-top: 10px;
          margin-bottom: 30px;

          &__icon {
            @media (max-width: 1199px) {
              margin-left: auto;
            }
            & + & {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}

.social-media-section {
  margin-top: 50px;
}

.instagram-feed {
  background-color: #f0f0f0;
  padding: 15px;
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    padding: 9px 0 24px;
    align-items: center;
    color: $preto;

    &__identifier {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      margin-left: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__button {
      margin: 0 0 0 auto;
      font-size: 12px;
      font-weight: 400;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 8px;
    gap: 8px;
    max-height: calc(380px - 93px);
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 1199px) {
      grid-template-columns: repeat(2, auto);
    }

    @media (max-width: 1199px) {
      grid-template-columns: repeat(3, auto);
    }

    @media (max-width: 575px) {
      grid-template-columns: repeat(4, auto);
    }

    @media (max-width: 375px) {
      grid-template-columns: repeat(3, auto);
    }

    &__item {
      width: 100%;
      height: 100%;

      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.youtube-video {
  background-color: #f0f0f0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    padding: 9px 0 24px;
    align-items: center;
    color: $preto;

    &__identifier {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      margin-left: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__button {
      margin: 0 0 0 auto;
      font-size: 12px;
      font-weight: 400;
    }
  }

  &__video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-top: auto;
    margin-bottom: auto;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.header-planos {
  &__login {
    padding: 16px 24px;
    background-color: $dourado;
    border-radius: 4px;
    color: $branco;
    position: absolute;
    right: 15px;
    top: 48px;
    font-weight: bold;

    &:hover {
      text-decoration: none;
      color: $branco;
      background-color: lighten($color: $dourado, $amount: 5);
    }

    @media (max-width: 575px) {
      top: 16px;
    }
  }

  img {
    @media (max-width: 425px) {
      margin-top: 104px !important;
    }
  }
}

.custom-breadcrumb {
  padding: 24px 32px;
  background-color: #f0f0f0;
  color: $preto;

  .mat {
    width: 100%;
    // display: flex;
    text-align: right;
  }

  &__title {
    margin: 0 0 3px 0;
    font-weight: 700;
    font-size: 24px;
  }

  &__item {
    color: currentColor;
    font-weight: 300;
    font-size: 13px;

    & + & {
      &::before {
        content: '>';
        margin: 0 8px;
        font-weight: 300;
      }
    }

    &--bold {
      @extend .custom-breadcrumb__item;
      font-weight: 700;
    }
  }
}

.facebook-feed {
  background-color: #f0f0f0;
  padding: 15px;
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    padding: 9px 0 24px;
    align-items: center;
    color: $preto;

    &__identifier {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      margin-left: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__button {
      margin: 0 0 0 auto;
      font-size: 12px;
      font-weight: 400;
    }
  }

  &__list {
    max-height: calc(380px - 93px);
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &__item {
      width: 100%;
      display: block;

      &:hover {
        text-decoration: none;
      }

      & + & {
        margin-top: 16px;
      }

      &__page-name,
      &__description {
        font-size: 12px;
      }

      &__page-name {
        font-weight: 700;
        margin-bottom: 2px;
        display: block;
        color: $preto;
      }

      &__description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 2px;
        color: $preto;
      }

      &__img {
        width: 100%;
        object-fit: cover;
        min-height: 225px;
      }
    }
  }
}

.sponsor-list {
  max-width: 100%;
  column-gap: 24px;
  row-gap: 12px;

  & + h1 {
    margin-top: 3rem;
  }

  & + & {
    margin-top: 3rem;
    // border-top: 1px solid $quaseBranco;
  }

  &--large {
    @extend .sponsor-list;

    &__item {
      max-width: 200px;
      max-height: 200px;
      justify-content: center;
      display: flex;
      align-items: center;

      & img {
        max-width: 100%;
        max-height: 100%;
      }
      @media (max-width: 410px) {
        max-width: 100%;
        height: auto;
      }
    }
  }

  &--medium {
    @extend .sponsor-list;

    &__item {
      max-width: 150px;
      max-height: 150px;
      justify-content: center;
      display: flex;
      align-items: center;
      
      & img {
        max-width: 100%;
        max-height: 100%;
      }
      @media (max-width: 410px) {
        max-width: 80%;
        height: auto;
      }
    }
  }

  &--small {
    @extend .sponsor-list;

    &__item {
      max-width: 100px;
      max-height: 100px;
      justify-content: center;
      display: flex;
      align-items: center;
      
      & img {
        max-width: 100%;
        max-height: 100%;
      }
      @media (max-width: 410px) {
        max-width: calc(50% - 20px);
        height: auto;
      }
    }

    &__fill-empty {
      width: 170px;
      height: 0;

      @media (max-width: 410px) {
        max-width: calc(50% - 20px);
      }
    }
  }
}

.text-yellow {
  color: $dourado;
}

.img-history {
  width: 100%;

  & + & {
    margin-top: 40px;
  }
}

@supports (display: grid) {
  .jogos {
    color: $preto;
    height: 100%;

    &__title {
      margin: 0 0 32px 0;
      font-size: 24px;
      font-weight: 700;

      @media (max-width: 600px) {
        font-size: 20px;
      }
    }

    &__checkin,
    &__buy-ticket {
      width: 100%;
    }

    &__info {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px 46px;
      gap: 24px 46px;
      width: 100%;
      word-break: break-all;

      @media (max-width: 1199px) {
        grid-column-gap: 2.75vw;
        column-gap: 2.75vw;
      }

      @media (max-width: 1199px) {
        grid-template-columns: auto auto;
      }

      @media (max-width: 900px) {
        grid-template-columns: 1fr;
        justify-items: center;
        align-items: center;
        grid-column-gap: unset;
        column-gap: unset;
      }

      @media (max-width: 400px) {
        justify-items: normal;
      }

      &__versus {
        pointer-events: none;
        display: flex;
        align-items: center;

        @media (max-width: 900px) {
          width: fit-content;
        }

        &__icon {
          font-size: 32px;
          font-weight: 700;
          margin: 0 8px;
          line-height: 39px;

          @media (max-width: 900px) {
            margin: 0 24px;
          }
        }

        &__team-logo {
          height: 70px;

          @media (max-width: 375px) {
            height: 60px;
          }
        }
      }

      &__details {
        text-align: start;
        font-size: 14px;
        line-height: 18px;

        @media (max-width: 900px) {
          width: fit-content;
        }

        & > span {
          display: block;
          margin-top: 5px;
        }

        &__league {
          font-weight: 700;
          color: $dourado;
        }

        &__teams {
          font-weight: 700;
          word-break: break-all;
        }
      }
    }
  }
}

.modal-90w {
  width: fit-content;
  max-width: 90%;

  @media (max-width: 768px) {
    max-width: 90%;
    margin: 0.5rem auto;
  }
}

.modal-100w {
  width: fit-content;
  max-width: 90%;

  @media (max-width: 768px) {
    max-width: 90%;
    margin: 0.5rem auto;
    height: auto;
  }
}

.modal-content {
  border: 0;
}

.btn-verm {
  background-color: $vermelhoVivo;
  color: $branco;

  &:hover {
    background-color: lighten($color: $vermelhoVivo, $amount: 2.5);
    color: $branco;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($color: $vermelhoVivo, $alpha: 0.25);
  }
}

.btn-dour {
  background-color: $dourado;
  color: $branco;

  &:hover {
    background-color: lighten($color: $dourado, $amount: 2.5);
    color: $branco;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($color: $dourado, $alpha: 0.25);
  }
}

.jogos-list {
  color: $preto;

  &__item {
    padding: 24px 17px;
    flex-wrap: wrap;
    border: 1px solid #f0f0f0;

    &:nth-child(even) {
      background-color: #f0f0f0;
    }

    @media (max-width: 768px) {
      padding: 22px 0;
    }

    &__date-time {
      strong {
        font-size: 50px;

        @media (max-width: 378px) {
          font-size: 40px;
        }

        @media (max-width: 360px) {
          font-size: 32px;
        }
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;
        font-weight: 300;

        @media (max-width: 441px) {
          margin-left: 12px;
        }
      }

      &__day {
        font-size: 18px;

        @media (max-width: 441px) {
          font-size: 16px;
        }

        @media (max-width: 360px) {
          font-size: 15px;
        }
      }

      &__year-month {
        font-size: 14px;

        @media (max-width: 441px) {
          font-size: 12px;
        }

        @media (max-width: 360px) {
          font-size: 11px;
        }
      }
    }

    &__teams {
      @media (min-width: 399px) {
        margin-left: auto;
      }

      span {
        font-weight: 300;
        font-size: 24px;
        margin: 0 auto;

        @media (max-width: 768px) {
          margin: 0 8px;
        }

        @media (max-width: 378px) {
          margin: 0 4px;
        }
      }

      img {
        @media (max-width: 768px) {
          width: 60px;
          height: 60px;
        }

        @media (max-width: 420px) {
          width: 40px;
          height: 40px;
        }

        @media (max-width: 360px) {
          width: 35px;
          height: 35px;
        }
      }
    }

    &__info {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__teams {
        font-weight: 700;
      }

      &__details {
        font-weight: 300;
      }
    }
  }
  &__item2 {
    padding: 24px 17px;
    flex-wrap: wrap;
    border: 1px solid #f0f0f0;

    @media (max-width: 768px) {
      padding: 22px 0;
    }

    &__date-time {
      strong {
        font-size: 50px;

        @media (max-width: 378px) {
          font-size: 40px;
        }

        @media (max-width: 360px) {
          font-size: 32px;
        }
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 20px;
        font-weight: 300;

        @media (max-width: 441px) {
          margin-left: 12px;
        }
      }

      &__day {
        font-size: 18px;

        @media (max-width: 441px) {
          font-size: 16px;
        }

        @media (max-width: 360px) {
          font-size: 15px;
        }
      }

      &__year-month {
        font-size: 14px;

        @media (max-width: 441px) {
          font-size: 12px;
        }

        @media (max-width: 360px) {
          font-size: 11px;
        }
      }
    }

    &__teams {
      @media (min-width: 399px) {
        margin-left: auto;
      }

      span {
        font-weight: 300;
        font-size: 24px;
        margin: 0 auto;

        @media (max-width: 768px) {
          margin: 0 8px;
        }

        @media (max-width: 378px) {
          margin: 0 4px;
        }
      }

      img {
        @media (max-width: 768px) {
          width: 60px;
          height: 60px;
        }

        @media (max-width: 420px) {
          width: 40px;
          height: 40px;
        }

        @media (max-width: 360px) {
          width: 35px;
          height: 35px;
        }
      }
    }

    &__info {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__teams {
        font-weight: 700;
      }

      &__details {
        font-weight: 300;
      }
    }
  }
}

.pagina-404 {
  min-height: calc(100vh - 219px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 476px) {
    align-items: flex-start;
    min-height: unset;
  }

  div {
    transform: translateY(-50%);

    @media (max-width: 833px) {
      transform: translateY(-20%);
    }

    @media (max-width: 555px) {
      transform: translateY(-40%);
    }

    @media (max-width: 476px) {
      transform: translateY(0);
      margin-top: 24px;
    }
  }

  img {
    z-index: -1;
    right: 0;
    top: 0;

    @media (max-width: 833px) {
      top: 10%;
    }

    @media (max-width: 476px) {
      position: unset !important;
      margin-top: 40px;
      width: 80%;
    }
  }

  &__secundario {
    font-size: 32px;
    font-weight: 700;
    color: $dourado;

    @media (max-width: 833px) {
      font-size: 22px;
    }

    @media (max-width: 350px) {
      font-size: 18px;
    }
  }

  &__primario {
    font-size: 120px;
    font-weight: 900;
    color: $vermelho;

    @media (max-width: 555px) {
      font-size: 80px;
    }

    @media (max-width: 476px) {
      font-size: 64px;
    }
  }

  &__info {
    font-size: 24px;
    color: $cinzaClaroAzulado;
    font-weight: 400;

    a {
      color: $dourado;
      font-weight: 700;
    }

    @media (max-width: 833px) {
      font-size: 20px;
    }

    @media (max-width: 350px) {
      font-size: 16px;
    }
  }
}

.logo-nuvoni {
  margin-left: auto;
  display: flex;
  align-items: flex-end;

  // img {
  //   opacity: 0.6;
  // }
}

.gap-3 {
  gap: 24px;
}

.form-steps-container {
  padding: 20px;
  max-width: 732px !important;
}

.btn-grey {
  @extend .login-button;
  background: $cinzaClaroAzulado;
  &:hover {
    background: darken($color: $cinzaClaroAzulado, $amount: 5);
  }
}

.login-nuvoni-2 {
  font-weight: 700;
  color: $vermelho;
  font-size: 18px;
  text-align: end;

  margin: 30px 25px 10px 20px;

  &:hover {
    color: $vermelho;
  }
}

.form-cadastro-section {
  width: 60vw;
  height: 100%;
  min-height: 100vh;
  background-color: $branco;
  display: flex;
  flex-direction: column;
  padding: 0;

  .cadastro-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > img {
    display: inline-block;
    position: absolute;
    top: 5%;
    width: 70px;

    @media (min-width: 768px) {
      display: none;
    }
  }

  & form {
    padding: 20px;
    width: 100%;
    max-width: 394px;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }
}

.steps-length {
  color: #d6d6d6;
  font-size: 1.75em;
  font-weight: bold;
}

.current-step {
  color: $dourado;
  font-size: 1.75em;
  font-weight: bold;
}

.step-finished {
  color: $verde;
}

.steps-title {
  color: $cinzaClaroAzulado;
  font-size: 1rem;
  font-weight: bold;
  margin-left: 16px;
  transform: translateY(-50%);
}

.ml-100 {
  margin-left: 100px;
}

.circular-stepper-cadastro {
  margin-top: 48px;
  margin-left: 20px;
  margin-bottom: 100px;
  max-width: 732px;
  width: 100%;
  @media (max-width: 768px) {
    & {
      margin-bottom: 24px;
    }
  }
}

.golden-title {
  color: $dourado;
  font-weight: bold;
  font-size: 24px;
}

.under-golden-title {
  font-size: 16px;
  // line-height: 19,5px;
}

.planos-card {
  position: relative;
  padding: 32px 24px;
  min-height: 345px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  box-shadow: 0px 12px 16px rgba(172, 183, 193, 0.15);
  border-radius: 4px;

  .title-planos-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .price-planos {
      display: flex;
      flex-direction: column;
      text-align: center;

      .price-planos-bottom {
        color: rgba(88, 50, 59, 0.75);
        font-size: 10px;
      }
      .price-planos-text {
        font-size: 16px;
        color: $dourado;
      }
      .big-number-card {
        font-size: 24px;
        color: $dourado;
        font-weight: 700;
      }
    }
  }

  @media (max-width: 1199px) {
    height: 100%;
  }

  @media (max-width: 575px) {
    margin-top: 24px;
  }
}

.plano-type {
  font-size: 24px;
  color: #717171;
  font-weight: bold;
  // padding: 38px 24px;
  margin: 0;
  width: 100%;
}
.plano-type-anual {
  margin-top: 10px;
}
.plano-type-gold {
  font-size: 32px;
  color: #58323b;
  font-weight: bold;
  margin: 0;
  width: 100%;
}

.content-planos-card {
  display: flex;
  flex-direction: column;
  flex: 1;

  padding: 0px;
  font-size: 14px;
  color: #717171;
  margin-top: 32px;
}
.content-planos-anual {
  margin-top: 43px;
}
.planos-card-gold {
  position: relative;
  padding: 32px 24px;

  margin-top: 0px;

  background: #ffffff;
  border: 1px solid #d89b49;
  box-sizing: border-box;
  box-shadow: 0px 12px 16px rgba(172, 183, 193, 0.15);
  border-radius: 4px;

  .title-planos-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .price-planos {
      display: flex;
      flex-direction: column;
      text-align: center;

      .price-planos-bottom {
        color: rgba(88, 50, 59, 0.75);
        font-size: 12px;
      }
      .price-planos-text {
        font-size: 24px;
        color: $dourado;
      }
      .big-number-card {
        font-size: 32px;
        color: $dourado;
        font-weight: 700;
      }
    }
  }
  @media (max-width: 575px) {
    margin-top: 24px;
  }
}

.lista-56-mb {
  margin-bottom: 56px;
}

.fs-20 {
  font-size: 20px;
}

.fs-16 {
  font-size: 16px;
}

.fs-32 {
  font-size: 32px;
}

.medal-planos {
  position: absolute;
  top: -1px;
  right: 8px;
  opacity: 0.75;
}

.button-planos,
.main-nav__nav__button {
  color: $branco;
  background-color: $dourado;
  border: 3px solid $dourado;

  &:hover {
    color: $dourado;
    background-color: $branco;
    border: 3px solid $dourado;
  }
}

.login-label {
  font-weight: 600;
  color: #717171;
}

.mh-19 {
  min-height: 19px;
}

.flex-1 {
  flex: 1;
}

.form-steps-container-concluido {
  margin-top: -100px;
  @media (max-width: 768px) {
    margin-top: -24px;
  }
}

.fix-input {
  flex: 0 auto;
}
.card-title {
  margin-right: 60px;
  // line-height: 14px;
  // max-width: 46ch;
  display: -webkit-box;
  inline-size: 274px;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #333;
  font-size: 16px;

  @media (max-width: 900px) {
    inline-size: 250px !important;
    overflow-wrap: break-word !important;
  }

  @media (max-width: 300px) {
    inline-size: 150px !important;
    overflow-wrap: break-word !important;
  }
}

.card-date {
  color: $cinza;
  inline-size: 274px;
  overflow-wrap: break-word;

  @media (max-width: 900px) {
    inline-size: 250px !important;
    overflow-wrap: break-word !important;
  }

  @media (max-width: 300px) {
    inline-size: 150px !important;
    overflow-wrap: break-word !important;
  }
}

.card-date_ {
  color: $cinza;
  inline-size: 320px;
  overflow-wrap: break-word;

  @media (max-width: 900px) {
    inline-size: 180px !important;
    overflow-wrap: break-word !important;
  }
}

.noticias-link {
  text-decoration: none;
  color: $branco;
  font-size: 24px;
  font-weight: bold;
}

.noticias--grande {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  word-break: break-all;
}

.noticias-data-tipo {
  color: $branco;
  text-decoration: none;
  font-size: 12px;
}

.noticias-data-tipo-separador {
  color: $branco;
  text-decoration: none;
  margin: 0px 8px;
  font-size: 12px;
}

.noticia-data-tipo-separador {
  color: $preto;
  text-decoration: none;
  margin: 0px 8px;
}

.noticias-pequenas-data-tipo-separador {
  color: $preto;
  text-decoration: none;
  font-size: 10px;
  margin: 0px 8px;
}

.noticia-data-tipo {
  color: $preto;
  text-decoration: none;
}

.noticia-data-tipo--bold {
  color: $preto;
  text-decoration: none;
  font-weight: bold;
}

.imagem-noticia--pequena {
  object-fit: cover;
  @media (min-width: 576px) {
    max-height: 200px;
    max-width: 250px;
  }
}

.noticias-fonte--pequena-bold {
  font-weight: bold;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 4rem;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.noticias-pequenas-data-tipo {
  color: $preto;
  font-size: 12px;
}

.noticias-fonte--media {
  font-size: 16px;
  color: $dourado;
}

.noticia-fonte--media {
  font-size: 32px;
  color: $dourado;
  word-break: break-all;
}

.noticias-fonte--pequena {
  font-size: 14px;
  color: $preto;
}
.noticias-fonte--pequena-gold {
  font-size: 14px;
  color: $dourado;
  font-weight: bold;
}

.font-12 {
  font-size: 12px;
  color: $preto;
}

// .noticia-pequena-container{
//   @media (max-width: 575px) {
//     padding:0;
//   }
// }

.noticia-pequena-texto {
  margin-left: 1.5rem;

  @media (max-width: 575px) {
    margin: 0;
    padding: 0;
  }
}

.noticia-pequena-texto-home {
  margin-left: 1.5rem;
  @media (max-width: 575px) {
    margin: 20px 0;
    padding: 0;
  }
}

.noticias-mobile {
  word-break: break-all;
  @media (max-width: 575px) {
    flex-direction: column;
    width: 100%;
    img {
      max-width: 100%;
      max-height: auto;
    }
  }
}

.filter-news-container {
  background-color: #f0f0f0;
}

.filter-news {
  justify-content: space-evenly;
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: $dourado;
  font-weight: bold;
  row-gap: 16px;
}

.filter-noticias {
  height: 40px;
  border-radius: 20px;
  background-color: $branco;
}

.form-pesquisa-news {
  display: flex;
  flex: 1;
}

// .nav-2{
//    padding: 2.5em;
// }

.coluna-noticia {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noticia-texto {
  // padding: 0 4em;

  // @media (max-width: 575px) {
  //   padding: 0;
  // }
  margin-top: 40px;
  max-width: 752px;
  width: 100%;
}

.noticia-imgs-texto {
  font-size: 16px;
  max-width: 752px;
  margin: 0 auto;
}

.noticia-imgs-container {
  display: flex;
  flex-direction: row;
}

.noticia-imgs {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  // a{
  //   &+a{
  //     margin-left: 24px;
  //   }
  // }
}

.button-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.noticia-imagem {
  // width: 100%;
  img {
    width: 100%;
  }
}

.imagem-noticia--pequena-home {
  object-fit: cover;

  @media (min-width: 576px) {
    max-height: 105px;
  }
}

.gradiente-leitura {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 120%);
  top: 0;
  left: 0;
}

@supports (display: grid) {
  .filter-noticias-grid {
    list-style: none;
    display: grid;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    // align-items: flex-end;
    grid-template-columns: repeat(5, auto);

    & > .filter-item {
      & > div > .filter-text {
        color: $dourado !important;
        font-weight: bold;
      }
    }

    @media (max-width: 450px) {
      align-content: space-around;
      justify-content: space-between;
      grid-template-columns: repeat(2, auto);
    }
  }
}

.capsule {
  padding: 20px;
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.num {
  color: $branco;
  text-decoration: none;
  // margin: 280px 0px 0px 30px;
  font-size: 18px;
  font-weight: 300;
  width: fit-content;
}

.num2 {
  color: $branco;
  text-decoration: none;
  // margin: 300px 0px 0px 30px;
  font-size: 20px;
}

.line {
  font-weight: 20px;
  width: 100%;
  border-bottom: 5px solid rgb(214, 153, 71);
  display: flex;
  padding-bottom: 0;
}

// .btn-y {
//   color: #fff;
//   padding: 6px 10px;
//   display: block;
//   color: #fff;
//   font-weight: 700;
//   background-color: #d69947;
//   border: 0;
//   transition: background-color 0.2s;
//   border-radius: 4px;
//   line-height: 16px;
// }

.btn-y {
  background-color: #d69947;
  color: #fff;
  height: 24px;
  width: 90px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border: 0;
  border-radius: 3px;
  padding: 0.375rem 0.75rem;

  &:hover {
    background-color: lighten($color: #d69947, $amount: 2.5);
    color: #fff;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($color: #d69947, $alpha: 0.25);
  }
}

.modal-header {
  background-color: $vermelho;
  font-family: sans-serif;
}

.modal-title {
  color: #fff;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.w-full {
  width: 100%;
}

.d2 {
  font-size: 32px;
  // margin-right: 10px;
  // margin-left: auto;
  padding: 8px;
}

.btm {
  background-color: #d69947;
  display: inline-block;
  font-weight: 700;
  color: #fff;
  // font: 20px Montserrat, sans-serif;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  // background-color: transparent;
  border: 1px solid transparent;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: $amarelo;
    border-color: #fff;
  }
  &:focus {
    background-color: $amarelo !important;
    box-shadow: 0 0 0 0.2rem rgba($color: #d69947, $alpha: 0.25);
    border-color: #d69947 !important;
  }
}

.box .p {
  font: 1rem 'Fira Sans', sans-serif;
}

.box .label {
  font: 1rem 'Fira Sans', sans-serif;
}

.box .input {
  margin: 0.4rem;
}

.check-cards-list {
  width: 100%;
  max-width: 1140px;
  margin: 64px auto 0;
  padding: 0 15px 18px;
  // overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  list-style: none;
  display: flex;

  .cards-list-space {
    width: 15px;
    min-width: 15px;
    height: 100%;
    min-height: 136px;
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  li {
    width: 350px;
    min-width: 300px;
    min-height: 136px;
    padding: 24px;
    border-radius: 4px;
    background-color: $branco;
    box-shadow: 0px 4px 16px rgba(172, 183, 193, 0.3);
    position: relative;

    @media (max-width: 900px) {
      width: 350px;
      min-width: 310px;
      min-height: 136px;
      padding: 24px;
      border-radius: 4px;
      background-color: $branco;
      box-shadow: 0px 4px 16px rgba(172, 183, 193, 0.3);
      position: relative;
    }

    & + li {
      margin-left: 30px;
    }

    span {
      color: $cinza;
    }

    div {
      display: flex;
      width: 100%;
      justify-content: space-between;
      // align-items: baseline;

      a {
        font-size: 14px;
        color: $cinza;
        text-decoration: underline;
      }
    }

    h1 {
      font-weight: 500;
      font-size: 42px;
      color: $socioTextoEscuro;
      margin: 14px 0 0 0;
    }

    svg {
      font-size: 11px;
      text-justify: center;
      border: 0;
      margin-top: 0.1rem;
      margin-right: 0.1rem;
      display: flex;

      @media (max-width: 900px) {
        // font-size: 10px;
        // justify-content: center;
        margin-top: 0.1rem;
        margin-right: 0.2rem;
      }
    }
  }

  @media (max-width: 975px) {
    .cards-list-space {
      display: block;
    }

    li + li {
      margin-left: 15px;
    }
  }

  @media (max-width: 1000px) {
    li {
      min-width: 150px;
      min-height: 60px;

      div > div > img {
        height: 35px !important;
      }
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;

    li {
      margin-left: auto;
      margin-right: auto;
      & + li {
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 450px) {
    margin-top: 24px;
  }

  @media (max-width: 390px) {

    li {
    width: fit-content !important;
    height: fit-content !important;
    max-height: min-content !important;
    max-width: 200px !important;

      strong {
        font-size: 12px;
      }

      span {
        font-size: 11px !important;
      }
    }
  }
}

.check-recorrencia-section {
  width: 100%;
  margin-top: 46px;

  h2 {
    color: $socioTextoEscuro;
    font-weight: 600;
    font-size: 24px;
    margin: 0;
  }

  ul {
    width: 100%;
    padding: 0;
    list-style: none;
    margin-top: 24px;
    margin-bottom: 0;

    li {
      background-color: $branco;
      border-radius: 4px;
      display: flex;
      // padding: 0 16px;
      align-items: center;
      position: relative;

      // & + li {
      //   margin-top: 8px;
      // }

      span {
        font-size: 14px;
        color: $socioTextoEscuro;

        @media (max-width: 500px) {
          // justify-content: center;
          // align-items: center;
          // text-align: center;
        }
      }

      .ax {
        color: $branco;
        font-size: 10px;
        line-height: 1.4;
        margin-right: 3px;
      }

      .socio-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        flex: 1;
      }

      @media (max-width: 450px) {
        & {
          height: auto;
          padding: 18px 24px;
        }

        span {
          text-align: unset;
        }

        .socio-list-label-status {
          position: absolute;
          top: 22px;
          right: 24px;
        }

        #valor {
          grid-area: valor;
          font-size: 22px;
        }

        #forma {
          grid-area: forma;
          font-size: 14px;
          color: #969cb2;
        }

        #date {
          grid-area: date;
          text-align: end;
          font-size: 14px;
          color: #969cb2;
        }

        .socio-grid {
          grid-template-columns: repeat(2, 1fr);
          grid-auto-flow: dense;
          grid-template-areas:
            'valor .'
            'forma date';
          grid-row-gap: 20px;
          row-gap: 20px;
        }
      }
    }
  }

  @media (max-width: 450px) {
    margin-top: 14px;
  }
}

.btn-x {
  background-color: #780000;
  height: 24px;
  width: 185px;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 6px 10px;
  display: flex;
  font-weight: 700;
  border: 0;
  border-radius: 3px;
  // line-height: 16px;
  padding: 0.375rem 0.75rem;
}

.btn-e {
  background-color: #780000;
  margin-left: 41px !important;
  color: #fff;
  height: 24px;
  width: 90px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border: 0;
  border-radius: 3px;
  padding: 0.375rem 0.75rem;

  &:hover {
    background-color: lighten($color: $vermelho, $amount: 2.5);
    color: #fff;
  }

  &:focus {
    background-color: #57040c !important;
    box-shadow: 0 0 0 0.2rem rgba($color: #800404, $alpha: 0.25);
    border-color: #b40e0e !important;
  }

  @media (max-width: 900px) {
    margin-left: 22px !important;
    color: #fff;
    height: 24px;
    // padding: 4px;
    width: 90px;
    font-size: 12px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
}

.btn-z {
  background-color: #00a783;
  height: 24px;
  width: 170px;
  display: flex;
  font-size: 14px;
  // align-items: center;
  justify-content: center;
  color: #fff;
  display: flex;
  font-weight: 700;
  border: 0;
  border-radius: 3px;
  // line-height: 16px;
  padding: 0.375rem 0.75rem;

  @media (max-width: 900px) {
    font-size: 12px;
    // padding: 4px;
    display: flex;
    width: 150px;
    height: 24px;
    // margin: 0px 0px 0px 10px !important;
    // margin-left: 20px;
    // justify-content: center;
    // align-items: center;
  }
}

.jogos_ {
  font-size: 20px;
  margin-left: 15px;
  justify-content: center;
  align-content: center;
  display: flex;
  color: #fff;
}

.d-block {
  // inline-size: 170px;
  overflow-wrap: break-word;
}

.over {
  inline-size: 172px;
  overflow-wrap: break-word;
}

.ds {
  inline-size: 172px;
  overflow-wrap: break-word;
}
.al {
  inline-size: 316px;
  word-break: break-all;

  @media (max-width: 900px) {
    width: fit-content;
    inline-size: 280px;
    padding-right: 16px;
    overflow-wrap: break-word;
  }
}
.al2 {
  inline-size: 316px;
  overflow-wrap: break-word;
  font-size: 14px !important;

  @media (max-width: 900px) {
    width: fit-content;
    inline-size: 280px;
    // padding-right: 16px;
    overflow-wrap: break-word !important;
  }
}

.card-title_o {
  margin-right: 60px;
  // line-height: 14px;
  // max-width: 46ch;
  display: -webkit-box;
  inline-size: 300px;
  overflow-wrap: break-word !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #333;
  font-size: 16px;

  @media (max-width: 900px) {
    inline-size: 200px;
    overflow-wrap: break-word;
  }
}

.card-title_dois {
  margin-right: 60px;
  display: -webkit-box;
  inline-size: 300px;
  overflow-wrap: break-word !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #333;
  font-size: 16px;

  @media (max-width: 900px) {
    inline-size: 200px;
    overflow-wrap: break-word;
  }
}

.card-date_o {
  color: $cinza;
  inline-size: 280px !important;
  overflow-wrap: break-word;

  @media (max-width: 900px) {
    inline-size: 180px !important;
    overflow-wrap: break-word !important;
  }
}

.games {
  color: $preto;
  height: 100%;

  &__title {
    margin: 0 0 32px 0;
    font-size: 24px;
    font-weight: 700;

    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  &__checkin,
  &__buy-ticket {
    width: 100%;
  }

  &__info {
    display: flex !important;
    // grid-template-columns: auto auto !important;
    // grid-gap: 24px 46px !important;
    // gap: 24px 46px !important;
    width: 100%;
    margin-bottom: 10px !important;

    @media (max-width: 1199px) {
      grid-column-gap: 2.75vw;
      column-gap: 2.75vw;
    }

    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      justify-items: center;
      align-items: center;
      grid-column-gap: unset;
      column-gap: unset;
    }

    @media (max-width: 400px) {
      justify-items: normal;
    }

    &__versus {
      pointer-events: none !important;
      display: flex !important;
      align-items: center;

      @media (max-width: 900px) {
        width: fit-content;
      }
      

      @media (max-width: 390px) {
        flex-direction: column;
      }

      &__icon_A {
        font-size: 30px !important;
        font-weight: 700;
        color: #242424 !important;
        line-height: 45px !important;

        @media (max-width: 900px) {
          margin: 0 24px;
        }

        @media (max-width: 636px) {
          margin: 0 !important;
        }
      }

      &__team-logo_A {
        height: 50px !important;
        padding-left: 45px;
        // margin-right: 40px;

        @media (max-width: 768px) {
          padding-left: 0 !important;
        }

        @media (max-width: 390px) {
          height: 60px;
          // margin-left: 10px !important;
          padding-left: 0;
        }
      }
      &__team-logo_B {
        height: 50px !important;
        padding-right: 45px;
        // margin-left: 40px;
        @media (max-width: 390px) {
          height: 60px;
          padding-right: 0;
        }
      }
    }

    &__details {
      text-align: start;
      font-size: 14px;
      line-height: 18px;

      @media (max-width: 900px) {
        width: fit-content;
      }

      & > span {
        display: block;
      }

      &__league {
        font-weight: 700;
        color: $dourado;
      }

      &__teams {
        font-weight: 700;
        word-break: break-all;
      }
    }
  }
}

.w-90 {
  width: 40vw;

  @media (max-width: 900px) {
    width: 100%;
    height: 85%;
    inline-size: 350px;
  }
}

.c-1 {
  inline-size: 150px;
  border: solid 1px #ddd;
  border-collapse: collapse;

  @media (max-width: 900px) {
    width: 100%;
    height: 85%;
    inline-size: 150px !important;
  }
}

.fd {
  font-size: 24px;
  margin-top: 8px !important;
  text-align: left !important;
}

.filter-carousel {
  margin-top: 24px;
  width: 100%;
  position: relative;

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    height: 100%;
    color: $dourado;

    &--prev {
      @extend .filter-carousel__arrow;
      left: 0;
      padding: 0 40px 0 8px;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    }

    &--next {
      @extend .filter-carousel__arrow;
      right: 0;
      padding: 0 8px 0 40px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    }
  }

  &__list {
    display: flex;
    list-style: none;
    padding: 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    &__item {
      & + .filter-carousel__list__item {
        margin-left: 8px;
      }
    }
  }
}

.check-statL {
  width: 100%;
  margin-top: 160px;
  padding: 4px;

  h2 {
    color: $socioTextoEscuro;
    font-weight: 600;
    font-size: 24px;
    margin: 0;

    @media (max-width: 980px) {
      color: #fff;
    }
  }

  ul {
    width: 100%;
    padding: 0;
    list-style: none;
    margin-top: 24px;
    margin-bottom: 0;

    li {
      height: 50px;
      background-color: $branco;
      border-radius: 4px;
      display: flex;
      padding: 0 16px;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-bottom: 10px;

      & + li {
        margin-top: 8px;
      }

      span {
        font-size: 16px;
        color: $socioTextoEscuro;

        @media (max-width: 500px) {
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }

      .check-grid {
        display: grid;
        grid-template-columns: 0.4fr 0.7fr 0.3fr 0.3fr 0.3fr;
        flex: 1;
        width: 100%;

        .stat-t {
          text-align: left;

          @media (max-width: 980px) {
            grid-area: valor;
            font-size: 22px;
          }
        }

        .stat {
          margin-left: auto;
          margin-bottom: auto;
          margin-top: auto;
          margin-right: 22px;

          @media (max-width: 980px) {
            margin-left: auto;
            margin-bottom: auto;
            margin-top: auto;
            margin-right: 0px;
          }
        }

        .checkin {
          padding-right: 20px;
          text-align: end;
        }

        .statt {
          word-break: break-all;

          @media (max-width: 630px) {
            max-width: 200px;
          }
        }

        @media (max-width: 980px) {
          div {
            margin-left: auto;
            margin-bottom: auto;
            margin-top: auto;
            margin-right: 0px;
          }
        }
      }

      @media (max-width: 980px) {
        & {
          height: auto;
          padding: 18px 24px;
        }

        span {
          text-align: unset;
          font-size: 16px;
        }

        .check-list-label-status {
          margin-left: auto;
          margin-bottom: auto;
          margin-top: auto;
          & .label-status {
            font-size: 9px !important;
          }
        }

        #valor {
          grid-area: valor;
          font-size: 22px;
        }

        #jogo {
          grid-area: forma;
          font-size: 14px;
          color: #969cb2;
        }

        #date {
          grid-area: date;
          text-align: end;
          font-size: 14px;
          color: #969cb2;
        }

        #checkin {
          text-align: end;
        }

        .check-grid {
          grid-template-columns: repeat(3, 1fr);
          grid-auto-flow: dense;
          grid-template-areas:
            'valor .'
            'forma date';
          grid-row-gap: 20px;
          text-align: start;
          row-gap: 20px;
          word-break: break-all;
        }
      }
    }
  }

  @media (max-width: 980px) {
    margin-top: 14px;
  }
}

div.flex.mr-auto {
  @media (max-width: 1199px) {
    margin-left: none;
  }

  a {
    margin-right: 10px;
  }
}

.MuiDataGrid-root {
  border-color: #f0f0f0 !important;
  border-bottom: none !important;
  letter-spacing: calc((10em / 1000) * -1) !important;

  .MuiDataGrid-main {
    color: $preto;
    background-color: #fff;
    font-size: 16px;
    font-family: 'Montserrat';

    .MuiDataGrid-columnHeaders {
      border-bottom: none;
      .MuiDataGrid-columnHeader {
        & .MuiDataGrid-columnSeparator {
          display: none;
        }
        @media (max-width: 670px) {
          padding: 0px !important;
        }
        &:focus {
          outline: none !important;
        }

        & .MuiDataGrid-columnHeaderTitleContainer {
          padding: 0px;
        }
      }
    }

    .MuiDataGrid-row {
      .MuiDataGrid-cell {
        border-bottom-color: #f0f0f0 !important;
        // max-width: none !important;
        & .Mui-error {
          background-color: #de002c20;
          color: $vermelhoVivo;
          height: 100%;
          outline: 1px solid $vermelhoVivo;
        }
        &.col-rank {
          font-weight: 700 !important;
          // max-width: none !important;
        }
        &:focus,
        &:focus-within {
          outline: $dourado solid 1px !important;
        }
        &--editing {
          border-bottom: $dourado solid 3px !important;
        }
        @media (max-width: 670px) {
          padding: 0px !important;
        }
        & button {
          transition: 0.15s ease-in-out;
          opacity: 0.3;
          @media (max-width: 1023px) {
            opacity: 1;
          }
          &:hover {
            opacity: 1;
          }
        }

        & input {
          font-family: 'Montserrat';
          text-align: center;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }
      }
      &:nth-child(2n) {
        background-color: #f0f0f0;
      }
    }

    @media (max-width: 1199px) {
      font-size: 14px;
    }

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
}

.img-input {
  cursor: pointer;
  max-width: 100%;
  border-radius: 0.25rem;
  background-color: $quaseBranco;
  box-shadow: 0px 2px 8px rgba(172, 183, 193, 0.3);
  transition: 0.2s ease-in-out;

  //essas opções de width e height podem ser facilmente substituidas nas extensões abaixo
  width: 125px;
  height: 125px;

  // o span nesse caso seria o Icon do noticiasCadastro nas imagens adicionais
  + span {
    opacity: 0;
    position: absolute;
    transition: 0.2s ease-in-out;
    pointer-events: none;
    // sobre o transform: https://miro.medium.com/max/1400/1*mB9MUxH3YCoikmD5AMVEnA.png
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  &:hover {
    opacity: 45%;
    + span {
      opacity: 1;
    }
  }

  &--banner {
    @extend .img-input;
    width: 320px;
    height: 180px;
  }

  &--elenco {
    @extend .img-input;
    width: 180px;
    height: 240px;
  }
}

.padCards {
  padding-bottom: 25px;
}

.btn-adicionar-pc {
  background-color: $verde;
  color: $branco;
  border: 0;
  font-size: 15px;

  &:hover {
    background-color: lighten($color: $verde, $amount: 2.5);
    color: $branco;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($color: $verde, $alpha: 0.25);
  }
}

.dispRow {
  display: flex;
  flex-direction: row;
  margin-left: -15px;
  margin-left: -15px;
}

.btn-transparent {
  background-color: transparent;
  border: none;
  size: 15;
}

.colorSemPlanos {
  color: #333 !important;
}

.deleteColor {
  color: #de002c;
  size: 20;
  &:hover {
    color: #72041a;
  }
}

.btn-desabled {
  :disabled {
    color: #6ea599;
  }
}

.btn-title{
  width: 100%;
  max-width: 300px;
}

button.edit-title{
  all: unset;
}

button.edit-title:focus{
  box-shadow: unset;
}

.dados-jogador {
  @media (max-width: 807px) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    flex-direction: column !important;

    .img-jogador {
      flex-direction: column!important;
      display: flex!important;
      padding-right: 15px;
      padding-left: 15px;
      max-width: 100%;

      .img-jogador-inside {
        width: 100%;
        max-width: 100%
      }

      .boxshadow {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
      }
    }

    .hist-jogador {
      margin-top: 20px;
      max-width: 100%;
    }
  }

  @media (max-width: 432px) {
    .img-jogador {
      max-height: 200px;
      height: 200px;

      .img-jogador-inside {
        max-height: 200px;
        height: 200px;
      }
        
      .boxshadow {
        min-width: 50px !important;
        min-height: 200px !important;
      }
    }

    .nome-jogador {
      white-space: unset !important;
    }
  }
}
